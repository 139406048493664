<template>
  <portal to="dialog-container">
    <div
      class="absolute z-80000 pointer-events-none w-full h-full inset-0"
    >
      <transition name="fade" appear>
        <div
          v-if="active"
          key="dialog-overlay"
          :class="overlayClass"
          @click="handleClick()"
        />
      </transition>
      <transition name="pop" appear>
        <div
          v-if="active"
          key="dialog-container"
          class="absolute m-auto w-full h-full z-80000 flex items-center justify-center pointer-events-none"
          role="dialog"
        >
          <div class="relative m-2 bg-white rounded-lg shadow-lg pointer-events-auto sm:max-w-xs sm:w-full dark:bg-gray-900 dark:border dark:border-gray-800">
            <o-scroll base-class="h-full w-full max-w-full max-h-[95svh]">
              <div class="px-3 pt-5 pb-4 sm:p-4 sm:pb-4">
                <div>
                  <slot name="heading">
                    <div v-if="icon" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-md opacity-75 mb-4 bg-gray-100 dark:bg-gray-500">
                      <o-icon :icon="icon" :size="22" />
                    </div>
                  </slot>
                  <div class="mt-3 text-center">
                    <slot name="title">
                      <h3 id="modal-headline" class="font-medium leading-6 text-md text-gray-700 mb-1 dark:text-gray-200">
                        {{ title }}
                      </h3>
                    </slot>
                    <slot name="info">
                      <p v-if="info" class="mb-1 text-sm leading-5 text-gray-600 dark:text-gray-300" v-html="info" />
                    </slot>
                    <div v-if="$slots.default" :class="slotClass">
                      <slot :active="active" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-3 py-3 sm:px-4 sm:flex sm:flex-col space-y-2">
                <o-button
                  v-for="(button, index) in buttons"
                  :key="index"
                  class="w-full h-9"
                  :variant="button.variant"
                  :flat="button.flat"
                  :outline="button.outline"
                  :disabled="button.disabled || loading"
                  @click="handleClick(button)"
                >
                  {{ button.text }}
                </o-button>
              </div>
            </o-scroll>

            <transition name="fade">
              <div v-if="loading" class="z-20 absolute w-full h-full inset-0 rounded-lg bg-gray-100 p-6 text-gray-800 text-sm font-medium flex flex-col items-center justify-center dark:bg-gray-900 dark:text-gray-100">
                <slot name="loading">
                  <o-loader
                    :scale="0.4"
                    class="h-10 w-10 mb-4"
                  />
                  <p>Loading</p>
                </slot>
              </div>
            </transition>
          </div>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
import colours from '~/mixins/elements/colours'

export default {
  name: 'ODialog',
  mixins: [colours],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    info: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'Info'
    },
    icon: {
      type: String,
      default: null
    },
    portal: {
      type: String,
      default: 'dialog-container'
    },
    container: {
      type: [String, Object],
      default: null
    },
    valid: {
      type: Function,
      default: () => true
    },
    overlayClass: {
      type: String,
      default: 'absolute inset-0 w-full h-full bg-black opacity-50 rounded-md z-80000 transition pointer-events-auto'
    },
    slotClass: {
      type: String,
      default: 'py-2'
    }
  },
  data () {
    return {
      active: false,
      loading: false,
      resolve: null,
      reject: null,
      default: {
        confirm: {
          text: 'Confirm',
          variant: 'primary',
          outline: false,
          flat: false,
          response: 'confirm',
          validate: true
        },
        cancel: {
          text: 'Cancel',
          variant: undefined,
          outline: true,
          flat: true,
          response: 'cancel',
          validate: false
        }
      }
    }
  },
  computed: {
    buttons () {
      return this.options.map((option) => {
        return Object.assign({ ...this.default[option.type] },  option)
      })
    }
  },
  methods: {
    async handleClick (options = { response: 'cancel' }) {
      const valid = !options.validate || await this.valid()

      if (valid) {
        this.loading = true
        this.$emit(options.response)

        const callback = options.callback

        if (callback) {
          const success = await callback()

          if (success) {
            this.close()
            this.resolve(options.response)
          }
        } else {
          this.close()
          this.resolve(options.response)
        }

        this.loading = false
      }
    },
    open () {
      this.active = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    close () {
      this.$emit('close')
      this.active = false
    }
  }
}
</script>

<style>
.pop-enter-active,
.pop-leave-active {
  transition: transform 0.3s, opacity 0.3s
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3);
}
</style>
