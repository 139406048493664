<template>
  <div>
    <o-dropdown
      v-if="account"
      :modifiers="[
        {
          name: 'offset',
          options: {
            offset: [5, 5]
          }
        }
      ]"
      :arrow="false"
      padding="p-0"
      placement="right-end"
      class="w-full"
    >
      <div class="w-full flex items-center justify-between">
        <div class="flex items-center">
          <img
            v-if="account.employee.image"
            :src="account.employee.image"
            class="inline-block h-7 w-7 bg-opacity-75 rounded-full text-white mr-3"
            :class="[
              collapse ? 'ml-px' : ' ml-1',
            ]"
          >

          <div
            v-if="!account.employee.image"
            class="inline-flex h-7 w-7 rounded-full text-white mr-3 items-center justify-center bg-white bg-opacity-15"
            :class="[
              collapse ? 'ml-px' : ' ml-1',
            ]"
          >
            <span>{{ account.employee.initials }}</span>
          </div>

          <div :class="[ collapse? 'sm:hidden' : '']" class="text-left leading-none opacity-95 mt-1">
            <p class="leading-none font-medium text-base">
              {{ account.employee.firstName }}
              {{ account.employee.lastName }}
            </p>
            <!--<p class="text-2xs font-medium opacity-75 uppercase tracking-wider">
              {{ account.location.title }}
            </p>-->
          </div>
        </div>

        <o-icon :class="[ collapse? 'sm:hidden' : '']" icon="accordion" :size="14" />
      </div>

      <template #content>
        <div class="w-full sm:w-80 sm:border sm:rounded-md overflow-hidden dark:border-gray-800">
          <div>
            <div class="flex items-center space-x-4 p-3 mt-2 -mb-1">
              <img
                v-if="account.employee.image"
                :src="account.employee.image"
                class="h-12 w-12 rounded-full border dark:border-gray-800"
              >
              <div>
                <p class="text-base font-medium">
                  {{ account.employee.firstName }} {{ account.employee.lastName }}
                </p>
                <p class="text-sm">
                  {{ account.location.title }}
                </p>
                <p class="text-xs opacity-60">
                  Account ID: #{{ account.location.idLocation }}
                </p>
              </div>
            </div>
            <div v-if="isAdmin || isDev">
              <div class="w-full h-px bg-gray-200 dark:bg-gray-800 my-3" />

              <p class="text-xs opacity-60 px-3">
                Admin
              </p>
              <o-history-link
                :item="$auth.info.location"
                model="Location"
                icon="history"
                label="Account History"
                component="ODropdownItem"
              />

              <!--<o-history-link
                :item="$auth.info.location"
                model="LocationProfile"
                icon="history"
                label="Location Profile History"
                component="ODropdownItem"
              />-->
            </div>

            <div class="w-full h-px bg-gray-200 dark:bg-gray-800 my-3" />

            <o-dropdown-item v-if="account.employee.pinHash" icon="login" :icon-size="16" @click="handleLock">
              Lock account
            </o-dropdown-item>

            <o-dropdown-item v-if="!demo" icon="switch" :icon-size="16" @click="handleSwitch">
              Switch user
            </o-dropdown-item>

            <!--<o-dropdown-item icon="user" :icon-size="16" @click="handleUserSettings">
              User settings
            </o-dropdown-item>-->

            <div class="w-full h-px bg-gray-200 dark:bg-gray-800 my-3" />

            <o-dropdown-item
              v-if="!demo && $grants.all({ account: [ 'billing'] })"
              :description="planLabel"
              icon="menuPlan"
              :icon-size="16"
              tag-name="nuxt-link"
              to="/account/billing"
            >
              Plan
            </o-dropdown-item>
            <o-dropdown-item
              v-if="$grants.all({ account: [ 'edit'] })"
              description="Manage account settings"
              icon="menuManage"
              :icon-size="16"
              tag-name="nuxt-link"
              to="/account"
            >
              Settings
            </o-dropdown-item>
            <o-dropdown-item
              description="Guides and FAQs"
              icon="menuSupport"
              :icon-size="16"
              tag-name="a"
              href="https://ovatu.com/au/help/guides"
              target="_blank"
            >
              Help
            </o-dropdown-item>
            <o-dropdown-item
              description="Features and changes"
              icon="update"
              :icon-size="16"
              tag-name="a"
              href="https://ovatu.com/au/updates"
              target="_blank"
              :prevent-default="false"
            >
              Updates
            </o-dropdown-item>
            <o-dropdown-submenu
              description="System/Dark/Light Mode"
              icon="theme"
              :icon-size="16"
              target="_blank"
              :prevent-default="false"
            >
              <span>Theme</span>

              <template #description>
                <template v-if="$colorMode.value === 'system'">
                  System (Auto)
                </template>

                <template v-if="$colorMode.value === 'light'">
                  Light
                </template>

                <template v-if="$colorMode.value === 'dark'">
                  Dark (Auto)
                </template>
              </template>

              <template #content>
                <o-dropdown-item icon="systemMode" :icon-size="16" @click="handleSetColorMode('system')">
                  <template #label>
                    <div class="flex-grow flex items-center justify-between w-full">
                      <span>System (Auto)</span>
                      <o-icon v-if="$colorMode.preference === 'system'" icon="success" />
                    </div>
                  </template>
                </o-dropdown-item>

                <o-dropdown-item icon="lightMode" :icon-size="16" @click="handleSetColorMode('light')">
                  <template #label>
                    <div class="flex-grow flex items-center justify-between w-full">
                      <span>Light</span>
                      <o-icon v-if="$colorMode.preference === 'light'" icon="success" />
                    </div>
                  </template>
                </o-dropdown-item>

                <o-dropdown-item icon="darkMode" :icon-size="16" @click="handleSetColorMode('dark')">
                  <template #label>
                    <div class="flex-grow flex items-center justify-between w-full">
                      <span>Dark</span>
                      <o-icon v-if="$colorMode.preference === 'dark'" icon="success" />
                    </div>
                  </template>
                </o-dropdown-item>
              </template>
            </o-dropdown-submenu>

            <div class="w-full h-px bg-gray-200 dark:bg-gray-800 my-3" />

            <o-dropdown-item @click="handleChangePassword">
              Change password
            </o-dropdown-item>

            <o-dropdown-item @click="handleChangePin">
              Change pin
            </o-dropdown-item>

            <div class="w-full h-px bg-gray-200 dark:bg-gray-800 my-3" />
            
            <template v-if="isAdmin || isDev">
              <o-dropdown-item
                icon="update"
                :icon-size="16"
                tag-name="div"
                :prevent-default="false"
              >
                <p>Remote Events</p>

                <div v-if="$centrifuge.instance" class="opacity-70">
                  <p class="mb-1">Client: {{ $centrifuge.instance._client }}</p>
                  <p>
                    Subs: <span v-for="(sub, key) in $centrifuge.instance._serverSubs">{{ key }}</span>
                  </p>
                </div>

                <div v-else class="opacity-70">
                  <p>Not connected</p>
                </div>
              </o-dropdown-item>
            </template>

            <o-dropdown-item v-if="!demo" class="mb-2" icon="logout" @click="handleLogout">
              Logout user
            </o-dropdown-item>

            <o-dropdown-item v-if="demo" href="https://ovatu.com/plans" class="mb-2" icon="logout">
              Leave demo
            </o-dropdown-item>
          </div>
        </div>
      </template>
    </o-dropdown>

    <!--<user-settings-modal ref="userSettingsModal" />-->

    <change-password-dialog
      ref="changePasswordDialog"
    />

    <change-pin-dialog
      ref="changePinDialog"
      @success="handleChangePinSuccess"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// import UserSettingsModal from '@/components/auth/elements/UserSettings'

import ChangePasswordDialog from '@/components/auth/actions/ChangePassword'
import ChangePinDialog from '@/components/auth/actions/ChangePin'

import {
  ODropdown,
  ODropdownItem,
  ODropdownDivider,
  ODropdownSubmenu
} from '@/components/elements'

export default {
  components: {
    // UserSettingsModal,
    ChangePasswordDialog,
    ChangePinDialog,
    ODropdown,
    ODropdownItem,
    ODropdownDivider,
    ODropdownSubmenu
  },
  computed: {
    ...mapGetters({
      collapse: 'menu'
    }),
    account () {
      return this.$auth.info
    },
    demo () {
      return this.$config.instance === 'demo'
    },
    planLabel () {
      return this.account?.plan?.infoLabel || ''
    },
    isDev () {
      return this.$config.instance === 'dev'
    },
    isAdmin () {
      return this.$auth?.info?.adminUser
    }
  },
  methods: {
    handleLock () {
      this.$auth.lock()
    },
    handleSwitch () {
      this.$auth.switch()
    },
    handleLogout () {
      this.$auth.logout()
    },
    handleChangePassword () {
      this.$refs.changePasswordDialog.open()
    },
    handleChangePin () {
      this.$refs.changePinDialog.open()
    },
    handleChangePinSuccess () {
      this.$auth.syncActive()
    },
    handleUserSettings () {
      this.$refs.userSettingsModal.open()
    },
    handleSetColorMode (mode) {
      this.$colorMode.preference = mode
    }
  }
}
</script>
