<template>
  <i @click="handleClick">
    <component
      :is="svg"
      :width="computedSize.width"
      :height="computedSize.height"
      class="leading-none"
      :stroke="stroke"
      :fill="fill ? 'currentColor' : undefined"
    />
  </i>
</template>

<script>
export const icons = {
  arrowUp: require('@/assets/images/icons/streamline/arrow-up.svg?inline'),
  arrowDown: require('@/assets/images/icons/streamline/arrow-down.svg?inline'),
  arrowLeft: require('@/assets/images/icons/streamline/arrow-left.svg?inline'),
  arrowRight: require('@/assets/images/icons/streamline/arrow-right.svg?inline'),
  apple: require('@/assets/images/icons/streamline/os-system-apple.svg?inline'),
  android: require('@/assets/images/icons/streamline/android-1.svg?inline'),
  web: require('@/assets/images/icons/streamline/monitor.svg?inline'),
  mobile: require('@/assets/images/icons/streamline/mobile-phone.svg?inline'),
  add: require('@/assets/images/icons/streamline/add.svg?inline'),
  address: require('@/assets/images/icons/streamline/pin-location-1.svg?inline'),
  subtract: require('@/assets/images/icons/streamline/subtract.svg?inline'),
  accordion: require('@/assets/images/icons/streamline/move-expand-vertical.svg?inline'),
  allowInternet: require('@/assets/images/icons/streamline/network-user.svg?inline'),
  archive: require('@/assets/images/icons/streamline/archive.svg?inline'),
  attendees: require('@/assets/images/icons/streamline/multiple-circle.svg?inline'),
  calendar: require('@/assets/images/icons/streamline/calendar.svg?inline'),
  closePanel: require('@/assets/images/icons/streamline/remove.svg?inline'),
  changeCustomer: require('@/assets/images/icons/streamline/single-neutral-actions-sync.svg?inline'),
  check: require('@/assets/images/icons/streamline/check-1.svg?inline'),
  cross: require('@/assets/images/icons/streamline/remove.svg?inline'),
  communications: require('@/assets/images/icons/streamline/conversation-chat-1.svg?inline'),
  creditCard: require('@/assets/images/icons/streamline/credit-card-dollar-1.svg?inline'),
  customerAdd: require('@/assets/images/icons/streamline/single-neutral-actions-add.svg?inline'),
  customerEdit: require('@/assets/images/icons/streamline/single-neutral-actions-edit-1.svg?inline'),
  customerMerge: require('@/assets/images/icons/streamline/diagram-fall-down.svg?inline'),
  customerResetPass: require('@/assets/images/icons/streamline/synchronize-arrows-lock.svg?inline'),
  customFields: require('@/assets/images/icons/streamline/list-bullets.svg?inline'),
  datepicker: require('@/assets/images/icons/streamline/calendar.svg?inline'),
  delete: require('@/assets/images/icons/streamline/bin-2.svg?inline'),
  edit: require('@/assets/images/icons/streamline/pencil-2.svg?inline'),
  ellipses: require('@/assets/images/icons/streamline/navigation-menu-horizontal.svg?inline'),
  email: require('@/assets/images/icons/streamline/envelope.svg?inline'),
  externalLink: require('@/assets/images/icons/streamline/expand-6.svg?inline'),
  expand: require('@/assets/images/icons/streamline/expand-2.svg?inline'),
  shrink: require('@/assets/images/icons/streamline/shrink-1.svg?inline'),
  forms: require('@/assets/images/icons/streamline/task-checklist-write.svg?inline'),
  giftCard: require('@/assets/images/icons/streamline/products-gift-tag.svg?inline'),
  history: require('@/assets/images/icons/streamline/synchronize-arrow-clock.svg?inline'),
  integrations: require('@/assets/images/icons/streamline/mood-happy.svg?inline'),
  pass: require('@/assets/images/icons/streamline/ticket.svg?inline'),
  placeholder: require('@/assets/images/icons/streamline/smile.svg?inline'),
  reservation: require('@/assets/images/icons/streamline/calendar-clock.svg?inline'),
  reservationCancel: require('@/assets/images/icons/streamline/calendar-block.svg?inline'),
  reservationCheckIn: require('@/assets/images/icons/streamline/single-neutral-actions-check-1.svg?inline'),
  reservationConfirm: require('@/assets/images/icons/streamline/calendar-check-1.svg?inline'),
  reservationMove: require('@/assets/images/icons/streamline/move-to-bottom.svg?inline'),
  reservationNoShow: require('@/assets/images/icons/streamline/single-neutral-actions-remove.svg?inline'),
  reservationPreview: require('@/assets/images/icons/streamline/calendar-search.svg?inline'),
  reservationRebook: require('@/assets/images/icons/streamline/calendar-refresh.svg?inline'),
  reservationClass: require('@/assets/images/icons/streamline/multiple-users-1.svg?inline'),
  sale: require('@/assets/images/icons/streamline/receipt-slip-1.svg?inline'),
  orderClose: require('@/assets/images/icons/streamline/receipt-slip-1.svg?inline'),
  services: require('@/assets/images/icons/streamline/cog-hand-give.svg?inline'),
  customerSegments: require('@/assets/images/icons/streamline/analytics-pie-2.svg?inline'),
  marketing: require('@/assets/images/icons/streamline/advertising-megaphone.svg?inline'),
  menuDashboard: require('@/assets/images/icons/streamline/house-chimney-2.svg?inline'),
  menuBookings: require('@/assets/images/icons/streamline/time-clock-circle-1.svg?inline'),
  menuSales: require('@/assets/images/icons/streamline/tags-double.svg?inline'),
  menuCustomers: require('@/assets/images/icons/streamline/multiple-neutral-2.svg?inline'),
  menuRoster: require('@/assets/images/icons/streamline/calendar-1.svg?inline'),
  menuMarketing: require('@/assets/images/icons/streamline/advertising-megaphone.svg?inline'),
  menuMessage: require('@/assets/images/icons/streamline/conversation-chat-1.svg?inline'),
  menuReports: require('@/assets/images/icons/streamline/performance-increase-2.svg?inline'),
  menuManage: require('@/assets/images/icons/streamline/cog-double-2.svg?inline'),
  menuOnlineBooking: require('@/assets/images/icons/streamline/network-settings.svg?inline'),
  menuIntegrations: require('@/assets/images/icons/streamline/module-four.svg?inline'),
  menuNotifications: require('@/assets/images/icons/streamline/alarm-bell.svg?inline'),
  menuAccount: require('@/assets/images/icons/streamline/single-neutral-circle.svg?inline'),
  menuHelp: require('@/assets/images/icons/streamline/question-circle.svg?inline'),
  menuHamburger: require('@/assets/images/icons/streamline/navigation-menu.svg?inline'),
  menuSearch: require('@/assets/images/icons/streamline/search.svg?inline'),
  menuMessages: require('@/assets/images/icons/streamline/messages-bubble-square.svg?inline'),
  menuAlerts: require('@/assets/images/icons/streamline/alarm-bell.svg?inline'),
  menuSupport: require('@/assets/images/icons/streamline/help-wheel.svg?inline'),
  menuNotes: require('@/assets/images/icons/streamline/notes-text-flip.svg?inline'),
  menuPlan: require('@/assets/images/icons/streamline/check-circle-1.svg?inline'),
  notes: require('@/assets/images/icons/streamline/notes-text-flip.svg?inline'),
  currency: require('@/assets/images/icons/streamline/currency-dollar-circle.svg?inline'),
  chart: require('@/assets/images/icons/streamline/analytics-bars.svg?inline'),
  export: require('@/assets/images/icons/streamline/share-2.svg?inline'),
  sms: require('@/assets/images/icons/streamline/conversation-chat-2.svg?inline'),
  customer: require('@/assets/images/icons/streamline/single-neutral.svg?inline'),
  customerNew: require('@/assets/images/icons/streamline/single-neutral-actions-add.svg?inline'),
  filter: require('@/assets/images/icons/streamline/filter-1.svg?inline'),
  search: require('@/assets/images/icons/streamline/search.svg?inline'),
  searchEnter: require('@/assets/images/icons/streamline/keyboard-arrow-return-1.svg?inline'),
  searchArrowDown: require('@/assets/images/icons/streamline/arrow-down.svg?inline'),
  searchArrowUp: require('@/assets/images/icons/streamline/arrow-up.svg?inline'),
  x: require('@/assets/images/icons/streamline/remove.svg?inline'),
  chevronUp: require('@/assets/images/icons/streamline/arrow-up-1.svg?inline'),
  chevronDown: require('@/assets/images/icons/streamline/arrow-down-1.svg?inline'),
  chevronLeft: require('@/assets/images/icons/streamline/arrow-left-1.svg?inline'),
  chevronRight: require('@/assets/images/icons/streamline/arrow-right-1.svg?inline'),
  chevronUpDouble: require('@/assets/images/icons/streamline/arrow-button-up-1.svg?inline'),
  chevronDownDouble: require('@/assets/images/icons/streamline/arrow-button-down-1.svg?inline'),
  chevronLeftDouble: require('@/assets/images/icons/streamline/arrow-button-left-1.svg?inline'),
  chevronRightDouble: require('@/assets/images/icons/streamline/arrow-button-right-1.svg?inline'),
  refresh: require('@/assets/images/icons/streamline/button-refresh-arrows.svg?inline'),
  product: require('@/assets/images/icons/streamline/shipment-upload.svg?inline'),
  save: require('@/assets/images/icons/streamline/floppy-disk.svg?inline'),
  success: require('@/assets/images/icons/streamline/check-circle-1.svg?inline'),
  danger: require('@/assets/images/icons/streamline/alert-circle.svg?inline'),
  warning: require('@/assets/images/icons/streamline/alert-circle.svg?inline'),
  info: require('@/assets/images/icons/streamline/information-circle.svg?inline'),
  successBold: require('@/assets/images/icons/streamline/check-circle-1.svg?inline'),
  dangerBold: require('@/assets/images/icons/streamline/alert-circle.svg?inline'),
  warningBold: require('@/assets/images/icons/streamline/alert-circle.svg?inline'),
  infoBold: require('@/assets/images/icons/streamline/information-circle.svg?inline'),
  notification: require('@/assets/images/icons/streamline/alarm-bell-ring.svg?inline'),
  circle: require('@/assets/images/icons/streamline/sign-badge-circle.svg?inline'),
  print: require('@/assets/images/icons/streamline/print-text.svg?inline'),
  company: require('@/assets/images/icons/streamline/products-briefcase.svg?inline'),
  phone: require('@/assets/images/icons/streamline/phone.svg?inline'),
  homePhone: require('@/assets/images/icons/streamline/phone-retro-1.svg?inline'),
  send: require('@/assets/images/icons/streamline/send-email.svg?inline'),
  variable: require('@/assets/images/icons/streamline/pin.svg?inline'),
  passwordHide: require('@/assets/images/icons/streamline/view-off.svg?inline'),
  passwordShow: require('@/assets/images/icons/streamline/view-1.svg?inline'),
  view: require('@/assets/images/icons/streamline/view-1.svg?inline'),
  drag: require('@/assets/images/icons/streamline/direction-button-arrows.svg?inline'),
  resize: require('@/assets/images/icons/streamline/resize-icon.svg?inline'),
  reorderHandle: require('@/assets/images/icons/streamline/resize-icon.svg?inline'),
  refund: require('@/assets/images/icons/streamline/refund-icon.svg?inline'),
  price: require('@/assets/images/icons/streamline/money-wallet.svg?inline'),
  sortUnset: require('@/assets/images/icons/streamline/data-transfer-vertical.svg?inline'),
  sortAsc: require('@/assets/images/icons/streamline/keyboard-arrow-up.svg?inline'),
  sortDesc: require('@/assets/images/icons/streamline/keyboard-arrow-down.svg?inline'),
  waitlist: require('@/assets/images/icons/streamline/task-list-clock.svg?inline'),
  scheduleOptions: require('@/assets/images/icons/streamline/cog-double-2.svg?inline'),
  unlock: require('@/assets/images/icons/streamline/lock-unlock.svg?inline'),
  photos: require('@/assets/images/icons/streamline/picture-polaroid-human.svg?inline'),
  closePanels: require('@/assets/images/icons/streamline/logout-2.svg?inline'),
  selectTileCheck: require('@/assets/images/icons/streamline/check-circle-1.svg?inline'),
  selectTileCircle: require('@/assets/images/icons/streamline/sign-badge-circle-alternate.svg?inline'),
  schedule: require('@/assets/images/icons/streamline/time-clock-circle-1.svg?inline'),
  payment: require('@/assets/images/icons/streamline/cash-payment-bag.svg?inline'),
  loyaltyPoints: require('@/assets/images/icons/streamline/rating-star-give-1.svg?inline'),
  customerCredit: require('@/assets/images/icons/streamline/gauge-dashboard.svg?inline'),
  cash: require('@/assets/images/icons/streamline/cash-payment-bill.svg?inline'),
  discount: require('@/assets/images/icons/streamline/discount-bubble.svg?inline'),
  charge: require('@/assets/images/icons/streamline/tags-add.svg?inline'),
  saleTip: require('@/assets/images/icons/streamline/wedding-money-piggy.svg?inline'),
  resetPass: require('@/assets/images/icons/streamline/synchronize-arrows-lock.svg?inline'),
  permission: require('@/assets/images/icons/streamline/login-keys.svg?inline'),
  resources: require('@/assets/images/icons/streamline/hammer-wench.svg?inline'),
  reorder: require('@/assets/images/icons/streamline/move-to-bottom.svg?inline'),
  preferences: require('@/assets/images/icons/streamline/cog-double-2.svg?inline'),
  copy: require('@/assets/images/icons/streamline/common-file-double-1.svg?inline'),
  details: require('@/assets/images/icons/streamline/common-file-text-settings.svg?inline'),
  employee: require('@/assets/images/icons/streamline/single-neutral.svg?inline'),
  reminder: require('@/assets/images/icons/streamline/task-finger-bandage.svg?inline'),
  bufferTime: require('@/assets/images/icons/streamline/hourglass-1.svg?inline'),
  tax: require('@/assets/images/icons/streamline/discount-1.svg?inline'),
  upload: require('@/assets/images/icons/streamline/cloud-upload.svg?inline'),
  minimiseGaps: require('@/assets/images/icons/streamline/shrink-vertical.svg?inline'),
  formItems: require('@/assets/images/icons/streamline/ui-columns.svg?inline'),
  consent: require('@/assets/images/icons/streamline/app-window-password-correct.svg?inline'),
  devices: require('@/assets/images/icons/streamline/laptop-settings.svg?inline'),
  backspace: require('@/assets/images/icons/streamline/keyboard-delete.svg?inline'),
  pin: require('@/assets/images/icons/streamline/password-lock.svg?inline'),
  undo: require('@/assets/images/icons/streamline/undo.svg?inline'),
  redo: require('@/assets/images/icons/streamline/redo.svg?inline'),
  logout: require('@/assets/images/icons/streamline/logout-2.svg?inline'),
  login: require('@/assets/images/icons/streamline/login-keys.svg?inline'),
  switch: require('@/assets/images/icons/streamline/button-refresh-arrows.svg?inline'),
  summary: require('@/assets/images/icons/streamline/analytics-pie-2.svg?inline'),
  applyStocktake: require('@/assets/images/icons/streamline/check-circle-1.svg?inline'),
  reservationRecurrence: require('@/assets/images/icons/streamline/rotate-forward.svg?inline'),
  markRead: require('@/assets/images/icons/streamline/email-action-check.svg?inline'),
  facebook: require('@/assets/images/icons/streamline/facebook.svg?inline'),
  twitter: require('@/assets/images/icons/streamline/twitter.svg?inline'),
  instagram: require('@/assets/images/icons/streamline/instagram.svg?inline'),
  message: require('@/assets/images/icons/streamline/messages-bubble-square.svg?inline'),
  sync: require('@/assets/images/icons/streamline/database-sync.svg?inline'),
  loading: require('@/assets/images/icons/streamline/synchronize-arrows.svg?inline'),
  cancel: require('@/assets/images/icons/streamline/calendar-block.svg?inline'),
  building: require('@/assets/images/icons/streamline/shop-1.svg?inline'),
  users: require('@/assets/images/icons/streamline/multiple-neutral-2.svg?inline'),
  user: require('@/assets/images/icons/streamline/single-neutral.svg?inline'),
  dollar: require('@/assets/images/icons/streamline/money-wallet.svg?inline'),
  menu: require('@/assets/images/icons/streamline/navigation-menu-3.svg?inline'),
  receive: require('@/assets/images/icons/streamline/receive.svg?inline'),
  widget: require('@/assets/images/icons/streamline/programming-browser-1.svg?inline'),
  review: require('@/assets/images/icons/streamline/messages-bubble-square-star.svg?inline'),
  gallery: require('@/assets/images/icons/streamline/picture-polaroid-human.svg?inline'),
  businessHours: require('@/assets/images/icons/streamline/time-clock-circle-1.svg?inline'),
  roster: require('@/assets/images/icons/streamline/calendar-1.svg?inline'),
  campaignOpened: require('@/assets/images/icons/streamline/check-email.svg?inline'),
  campaignUnopened: require('@/assets/images/icons/streamline/disable-email.svg?inline'),
  campaignClicked: require('@/assets/images/icons/streamline/double-click.svg?inline'),
  campaignBounced: require('@/assets/images/icons/streamline/404.svg?inline'),
  campaignComplained: require('@/assets/images/icons/streamline/smiley-bad.svg?inline'),
  campaignUnsubscribed: require('@/assets/images/icons/streamline/stop-sign.svg?inline'),
  campaignReport: require('@/assets/images/icons/streamline/performance-increase-2.svg?inline'),
  file: require('@/assets/images/icons/streamline/double-file.svg?inline'),
  promote: require('@/assets/images/icons/streamline/advertising-megaphone.svg?inline'),
  reply: require('@/assets/images/icons/streamline/reply.svg?inline'),
  approve: require('@/assets/images/icons/streamline/check-circle-1.svg?inline'),
  template: require('@/assets/images/icons/streamline/template.svg?inline'),
  brand: require('@/assets/images/icons/streamline/brand.svg?inline'),
  statusType: require('@/assets/images/icons/streamline/status-type.svg?inline'),
  location: require('@/assets/images/icons/streamline/location.svg?inline'),
  purchaseOrder: require('@/assets/images/icons/streamline/purchase-order.svg?inline'),
  supplier: require('@/assets/images/icons/streamline/supplier.svg?inline'),
  stockTake: require('@/assets/images/icons/streamline/stock-take.svg?inline'),
  unlinked: require('@/assets/images/icons/streamline/unlinked.svg?inline'),
  preset: require('@/assets/images/icons/streamline/preset.svg?inline'),
  policy: require('@/assets/images/icons/streamline/task-checklist-write.svg?inline'),
  columns: require('@/assets/images/icons/streamline/ui-columns.svg?inline'),
  dataBrowser: require('@/assets/images/icons/streamline/data-browser.svg?inline'),
  connect: require('@/assets/images/icons/streamline/hyperlink.svg?inline'),
  moveLeft: require('@/assets/images/icons/streamline/move-left.svg?inline'),
  moveRight: require('@/assets/images/icons/streamline/move-right.svg?inline'),
  download: require('@/assets/images/icons/streamline/download-bottom.svg?inline'),
  productCategory: require('@/assets/images/icons/streamline/hierarchy-6.svg?inline'),
  brush: require('@/assets/images/icons/streamline/color-brush-3.svg?inline'),
  eraser: require('@/assets/images/icons/streamline/eraser.svg?inline'),
  stamp: require('@/assets/images/icons/streamline/design-tool-stamp.svg?inline'),
  serviceCategory: require('@/assets/images/icons/streamline/hierarchy-6.svg?inline'),
  resourceGroup: require('@/assets/images/icons/streamline/hierarchy-6.svg?inline'),
  locked: require('@/assets/images/icons/streamline/lock-6.svg?inline'),
  news: require('@/assets/images/icons/streamline/newspaper-fold.svg?inline'),
  blog: require('@/assets/images/icons/streamline/conversation-chat-2.svg?inline'),
  beacon: require('@/assets/images/icons/streamline/conversation-chat-2.svg?inline'),
  feature: require('@/assets/images/icons/streamline/rating-star-ribbon.svg?inline'),
  update: require('@/assets/images/icons/streamline/information-circle.svg?inline'),
  list: require('@/assets/images/icons/streamline/layout-headline.svg?inline'),
  grid: require('@/assets/images/icons/streamline/layout-module-1.svg?inline'),
  library: require('@/assets/images/icons/streamline/saving-bank-international.svg?inline'),
  heartHands: require('@/assets/images/icons/streamline/love-heart-hold.svg?inline'),
  alignCenter: require('@/assets/images/icons/streamline/align-center.svg?inline'),
  alignJustify: require('@/assets/images/icons/streamline/align-justify.svg?inline'),
  alignLeft: require('@/assets/images/icons/streamline/align-left.svg?inline'),
  alignRight: require('@/assets/images/icons/streamline/align-right.svg?inline'),
  bold: require('@/assets/images/icons/streamline/bold.svg?inline'),
  codeBlock: require('@/assets/images/icons/streamline/code-box-line.svg?inline'),
  codeLine: require('@/assets/images/icons/streamline/code-line.svg?inline'),
  blockQuote: require('@/assets/images/icons/streamline/double-quotes-l.svg?inline'),
  draggable: require('@/assets/images/icons/streamline/draggable.svg?inline'),
  h1: require('@/assets/images/icons/streamline/h-1.svg?inline'),
  h2: require('@/assets/images/icons/streamline/h-2.svg?inline'),
  h3: require('@/assets/images/icons/streamline/h-3.svg?inline'),
  heading: require('@/assets/images/icons/streamline/heading.svg?inline'),
  italic: require('@/assets/images/icons/streamline/italic.svg?inline'),
  bulletList: require('@/assets/images/icons/streamline/list-ordered.svg?inline'),
  orderedList: require('@/assets/images/icons/streamline/list-unordered.svg?inline'),
  strikethrough: require('@/assets/images/icons/streamline/strikethrough.svg?inline'),
  paragraph: require('@/assets/images/icons/streamline/italic.svg?inline'),
  editorUndo: require('@/assets/images/icons/streamline/arrow-go-back-line.svg?inline'),
  editorRedo: require('@/assets/images/icons/streamline/arrow-go-forward-line.svg?inline'),
  deleteColumn: require('@/assets/images/icons/streamline/delete-column.svg?inline'),
  deleteRow: require('@/assets/images/icons/streamline/delete-row.svg?inline'),
  addColumnBefore: require('@/assets/images/icons/streamline/insert-column-left.svg?inline'),
  addColumnAfter: require('@/assets/images/icons/streamline/insert-column-right.svg?inline'),
  addRowAfter: require('@/assets/images/icons/streamline/insert-row-bottom.svg?inline'),
  addRowBefore: require('@/assets/images/icons/streamline/insert-row-top.svg?inline'),
  toggleCellMerge: require('@/assets/images/icons/streamline/split-cells-horizontal.svg?inline'),
  table: require('@/assets/images/icons/streamline/table-2.svg?inline'),
  toggleHeaderRow: require('@/assets/images/icons/streamline/table-line.svg?inline'),
  hero: require('@/assets/images/icons/streamline/layout-top-line.svg?inline'),
  columns: require('@/assets/images/icons/streamline/layout-column-line.svg?inline'),
  editLink: require('@/assets/images/icons/streamline/edit-2-line.svg?inline'),
  link: require('@/assets/images/icons/streamline/link.svg?inline'),
  removeLink: require('@/assets/images/icons/streamline/link-unlink.svg?inline'),
  button: require('@/assets/images/icons/streamline/add-box-line.svg?inline'),
  remove: require('@/assets/images/icons/streamline/delete-bin-line.svg?inline'),
  image: require('@/assets/images/icons/streamline/image-line.svg?inline'),
  customPage: require('@/assets/images/icons/streamline/browser-page-text-1.svg?inline'),
  minisiteMenu: require('@/assets/images/icons/streamline/navigation-menu-2.svg?inline'),
  alignImageLeft: require('@/assets/images/icons/streamline/align-image-left.svg?inline'),
  alignImageRight: require('@/assets/images/icons/streamline/align-image-right.svg?inline'),
  alignImageCenter: require('@/assets/images/icons/streamline/align-image-center.svg?inline'),
  deleteImage: require('@/assets/images/icons/streamline/delete-image.svg?inline'),
  editorBlocks: require('@/assets/images/icons/streamline/instance-line.svg?inline'),
  darkMode: require('@/assets/images/icons/streamline/night-moon-new.svg?inline'),
  lightMode: require('@/assets/images/icons/streamline/light-mode-sunny.svg?inline'),
  systemMode: require('@/assets/images/icons/streamline/laptop.svg?inline'),
  theme: require('@/assets/images/icons/streamline/color-palette-sample.svg?inline'),
  fieldAnnotation: require('@/assets/images/icons/streamline/graphic-tablet-draw-1.svg?inline'),
  fieldCheckbox: require('@/assets/images/icons/streamline/check-2.svg?inline'),
  fieldDate: require('@/assets/images/icons/streamline/calendar-edit.svg?inline'),
  fieldNumber: require('@/assets/images/icons/streamline/paginate-filter-3.svg?inline'),
  fieldSelectMulti: require('@/assets/images/icons/streamline/list-bullets-1.svg?inline'),
  fieldSelectOne: require('@/assets/images/icons/streamline/list-bullets.svg?inline'),
  fieldSignature: require('@/assets/images/icons/streamline/content-pen-write.svg?inline'),
  fieldText: require('@/assets/images/icons/streamline/pencil-write-3.svg?inline'),
  fieldTextLong: require('@/assets/images/icons/streamline/pencil-write-2.svg?inline'),
  fieldToggle: require('@/assets/images/icons/streamline/question-circle.svg?inline'),
  layoutLetters: require('@/assets/images/icons/streamline/font-size.svg?inline'),
  layoutParagraph: require('@/assets/images/icons/streamline/paragraph-justified-align.svg?inline'),
  dragHere: require('@/assets/images/icons/streamline/cursor-select-2.svg?inline'),
  newChat: require('@/assets/images/icons/streamline/pencil-write.svg?inline'),
  attachment: require('@/assets/images/icons/streamline/attachment.svg?inline'),
}

export default {
  name: 'OIcon',
  props: {
    icon: {
      type: [String, Array],
      required: true
    },
    size: {
      type: [String, Number],
      default: 16
    },
    height: {
      type: Number,
      default: null
    },
    width: {
      type: Number,
      default: null
    },
    fill: {
      type: Boolean,
      default: false
    },
    stroke: {
      type: String,
      default: 'currentColor'
    }
  },
  computed: {
    svg () {
      return icons[this.icon] || icons.placeholder
    },
    computedSize () {
      const { size, height, width } = this

      return {
        height: height || size,
        width: width || size
      }
    }
  },
  methods: {
    handleClick ($event) {
      this.$emit('click', $event)
    }
  }
}
</script>
