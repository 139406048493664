<template>
  <container v-bind="container">
    <template #leading>
      <button v-if="!slim" class="bg-transparent bg-gray-100 dark:bg-gray-900 hover:bg-blue-200 dark:hover:bg-blue-900/50 transition-colors duration-200 m-1 ml-0 w-7 flex items-center justify-center h-7 rounded" :class="{ 'pointer-events-none' : disabled }" @click="decrease">
        <o-icon icon="subtract" :size="10" />
      </button>

      <slot name="leading" />
    </template>

    <input
      ref="input"
      v-model="currentValue"
      type="number"
      class="flex-grow px-2 h-full w-full bg-transparent text-sm"
      :max="max"
      :min="min"
      :step="step"
      v-bind="attributes"
      v-on="handlers"
    >

    <template #trailing>
      <slot name="trailing">
        <div v-if="trailing" class="pr-2 pl-2 opacity-80 leading-none text-base border-l ml-2 border-gray-200 dark:border-gray-800">
          <p class="leading-none text-sm">
            {{ trailing }}
          </p>
        </div>
      </slot>

      <button v-if="!slim" class="bg-transparent bg-gray-100 dark:bg-gray-900 hover:bg-blue-200 dark:hover:bg-blue-900/50 transition-colors duration-200 m-1 mr-0 w-7 flex items-center justify-center h-7 rounded" :class="{ 'pointer-events-none' : disabled }" @click="increase">
        <o-icon icon="add" :size="10" />
      </button>

      <div v-if="slim" class="flex flex-col h-full divide-y border rounded">
        <button class="bg-gray-100 dark:bg-gray-800 hover:bg-blue-200 hover:dark:bg-gray-900 transition-colors duration-200 w-5 flex items-center justify-center h-1/2" :class="{ 'pointer-events-none' : disabled }" @click="increase">
          <o-icon icon="add" :size="8" />
        </button>
        <button class="bg-gray-100 dark:bg-gray-800 hover:bg-blue-200 hover:dark:bg-gray-900 transition-colors duration-200 w-5 flex items-center justify-center h-1/2" :class="{ 'pointer-events-none' : disabled }" @click="decrease">
          <o-icon icon="subtract" :size="8" />
        </button>
      </div>
    </template>
  </container>
</template>

<script>
import Container from './Container'

import classes from '@/mixins/fields/classes.js'
import attributes from '@/mixins/fields/attributes.js'
import methods from '@/mixins/fields/methods.js'
import input from '@/mixins/fields/input.js'

export default {
  components: {
    Container
  },
  mixins: [attributes, classes, methods, input],
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    increment: {
      type: Number,
      default: 1
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 99999
    },
    step: {
      type: String,
      default: 'any'
    },
    slim: {
      type: Boolean,
      default: false
    },
    defaultSizeClass: {
      type: [String, Object, Array],
      default: 'p-1'
    },
    largeSizeClass: {
      type: [String, Object, Array],
      default: 'p-4 text-lg'
    },
    smallSizeClass: {
      type: [String, Object, Array],
      default: 'p-px text-sm'
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (value) {
        if (typeof value === 'string') {
          value = Number(value)
        }

        this.$emit('input', value)
      }
    }
  },
  methods: {
    increase () {
      const current = this.currentValue !== null ? this.currentValue : this.placeholder || null
      const value = current + this.increment

      if (value <= this.max) {
        this.currentValue = value
      }
    },
    decrease () {
      const current = this.currentValue !== null ? this.currentValue : this.placeholder || null
      const value = current - this.increment

      if (this.min <= value) {
        this.currentValue = value
      }
    }
  }
}
</script>

<style>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
