import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _21f1248e = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _b9a1f508 = () => interopDefault(import('../pages/account/index/index.vue' /* webpackChunkName: "pages/account/index/index" */))
const _f036f3b6 = () => interopDefault(import('../pages/account/index/billing.vue' /* webpackChunkName: "pages/account/index/billing" */))
const _91bd6af8 = () => interopDefault(import('../pages/account/index/calendar.vue' /* webpackChunkName: "pages/account/index/calendar" */))
const _27790f87 = () => interopDefault(import('../pages/account/index/devices.vue' /* webpackChunkName: "pages/account/index/devices" */))
const _0693c4e9 = () => interopDefault(import('../pages/account/index/exports.vue' /* webpackChunkName: "pages/account/index/exports" */))
const _683d9636 = () => interopDefault(import('../pages/account/index/integrations.vue' /* webpackChunkName: "pages/account/index/integrations" */))
const _10762410 = () => interopDefault(import('../pages/account/index/policy.vue' /* webpackChunkName: "pages/account/index/policy" */))
const _47ff8e9c = () => interopDefault(import('../pages/account/index/privacy.vue' /* webpackChunkName: "pages/account/index/privacy" */))
const _482b8363 = () => interopDefault(import('../pages/account/index/referral.vue' /* webpackChunkName: "pages/account/index/referral" */))
const _075a14f4 = () => interopDefault(import('../pages/account/index/security.vue' /* webpackChunkName: "pages/account/index/security" */))
const _b76b4f3a = () => interopDefault(import('../pages/account/index/sessions.vue' /* webpackChunkName: "pages/account/index/sessions" */))
const _6310a625 = () => interopDefault(import('../pages/account/index/storage.vue' /* webpackChunkName: "pages/account/index/storage" */))
const _2ece409c = () => interopDefault(import('../pages/account/index/users.vue' /* webpackChunkName: "pages/account/index/users" */))
const _9d736bd4 = () => interopDefault(import('../pages/account/index/users/owner.vue' /* webpackChunkName: "pages/account/index/users/owner" */))
const _75685dc0 = () => interopDefault(import('../pages/account/index/users/roles.vue' /* webpackChunkName: "pages/account/index/users/roles" */))
const _74fd9a2a = () => interopDefault(import('../pages/account/index/users/users.vue' /* webpackChunkName: "pages/account/index/users/users" */))
const _2fea0e21 = () => interopDefault(import('../pages/alerts/index.vue' /* webpackChunkName: "pages/alerts/index" */))
const _6703e7b2 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _16da7bae = () => interopDefault(import('../pages/auth/index/find/index.vue' /* webpackChunkName: "pages/auth/index/find/index" */))
const _0e5420bc = () => interopDefault(import('../pages/auth/index/find/index/accounts.vue' /* webpackChunkName: "pages/auth/index/find/index/accounts" */))
const _0de70643 = () => interopDefault(import('../pages/auth/index/find/index/code.vue' /* webpackChunkName: "pages/auth/index/find/index/code" */))
const _5bf6d21a = () => interopDefault(import('../pages/auth/index/find/index/domain.vue' /* webpackChunkName: "pages/auth/index/find/index/domain" */))
const _7329f3b6 = () => interopDefault(import('../pages/auth/index/find/index/email.vue' /* webpackChunkName: "pages/auth/index/find/index/email" */))
const _67df6c8c = () => interopDefault(import('../pages/auth/index/integration/index.vue' /* webpackChunkName: "pages/auth/index/integration/index" */))
const _917124a4 = () => interopDefault(import('../pages/auth/index/integration/index/_guid/connect.vue' /* webpackChunkName: "pages/auth/index/integration/index/_guid/connect" */))
const _6e8cfd62 = () => interopDefault(import('../pages/auth/index/login/index.vue' /* webpackChunkName: "pages/auth/index/login/index" */))
const _0ff7cedd = () => interopDefault(import('../pages/auth/index/login/index/code.vue' /* webpackChunkName: "pages/auth/index/login/index/code" */))
const _999b8348 = () => interopDefault(import('../pages/auth/index/login/index/email.vue' /* webpackChunkName: "pages/auth/index/login/index/email" */))
const _72eea88b = () => interopDefault(import('../pages/auth/index/login/index/password.vue' /* webpackChunkName: "pages/auth/index/login/index/password" */))
const _051be6b8 = () => interopDefault(import('../pages/auth/index/pin.vue' /* webpackChunkName: "pages/auth/index/pin" */))
const _c423f096 = () => interopDefault(import('../pages/auth/index/reset/index.vue' /* webpackChunkName: "pages/auth/index/reset/index" */))
const _4cf30434 = () => interopDefault(import('../pages/auth/index/reset/index/confirm.vue' /* webpackChunkName: "pages/auth/index/reset/index/confirm" */))
const _6825e042 = () => interopDefault(import('../pages/auth/index/reset/index/email.vue' /* webpackChunkName: "pages/auth/index/reset/index/email" */))
const _79667da9 = () => interopDefault(import('../pages/auth/index/reset/index/success.vue' /* webpackChunkName: "pages/auth/index/reset/index/success" */))
const _5e578a3e = () => interopDefault(import('../pages/auth/index/switch.vue' /* webpackChunkName: "pages/auth/index/switch" */))
const _a6a5f348 = () => interopDefault(import('../pages/auth/index/token.vue' /* webpackChunkName: "pages/auth/index/token" */))
const _b0163cb4 = () => interopDefault(import('../pages/auth/index/verify.vue' /* webpackChunkName: "pages/auth/index/verify" */))
const _68dea230 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _00d23a2d = () => interopDefault(import('../pages/demo/index.vue' /* webpackChunkName: "pages/demo/index" */))
const _6fc6a4be = () => interopDefault(import('../pages/elements.vue' /* webpackChunkName: "pages/elements" */))
const _5afa7efe = () => interopDefault(import('../pages/export/index.vue' /* webpackChunkName: "pages/export/index" */))
const _37619940 = () => interopDefault(import('../pages/history/index.vue' /* webpackChunkName: "pages/history/index" */))
const _660b25c8 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _77e45f6f = () => interopDefault(import('../pages/manage/index.vue' /* webpackChunkName: "pages/manage/index" */))
const _708156b2 = () => interopDefault(import('../pages/manage/index/index.vue' /* webpackChunkName: "pages/manage/index/index" */))
const _505f259c = () => interopDefault(import('../pages/manage/index/appointment-status-types.vue' /* webpackChunkName: "pages/manage/index/appointment-status-types" */))
const _6e8f31c8 = () => interopDefault(import('../pages/manage/index/brands.vue' /* webpackChunkName: "pages/manage/index/brands" */))
const _652c5ab5 = () => interopDefault(import('../pages/manage/index/cancellation-reasons.vue' /* webpackChunkName: "pages/manage/index/cancellation-reasons" */))
const _fb4c1cc2 = () => interopDefault(import('../pages/manage/index/charges.vue' /* webpackChunkName: "pages/manage/index/charges" */))
const _46ed98f5 = () => interopDefault(import('../pages/manage/index/custom-fields.vue' /* webpackChunkName: "pages/manage/index/custom-fields" */))
const _078a8c35 = () => interopDefault(import('../pages/manage/index/customer-gift-cards.vue' /* webpackChunkName: "pages/manage/index/customer-gift-cards" */))
const _5285e100 = () => interopDefault(import('../pages/manage/index/customer-pass.vue' /* webpackChunkName: "pages/manage/index/customer-pass" */))
const _697e13bc = () => interopDefault(import('../pages/manage/index/details.vue' /* webpackChunkName: "pages/manage/index/details" */))
const _007fbd32 = () => interopDefault(import('../pages/manage/index/discounts.vue' /* webpackChunkName: "pages/manage/index/discounts" */))
const _1f06047c = () => interopDefault(import('../pages/manage/index/email.vue' /* webpackChunkName: "pages/manage/index/email" */))
const _e0f7b036 = () => interopDefault(import('../pages/manage/index/employees.vue' /* webpackChunkName: "pages/manage/index/employees" */))
const _0d8cc38f = () => interopDefault(import('../pages/manage/index/forms.vue' /* webpackChunkName: "pages/manage/index/forms" */))
const _47057685 = () => interopDefault(import('../pages/manage/index/location.vue' /* webpackChunkName: "pages/manage/index/location" */))
const _612bf346 = () => interopDefault(import('../pages/manage/index/loyalty-program.vue' /* webpackChunkName: "pages/manage/index/loyalty-program" */))
const _5c8e6e3e = () => interopDefault(import('../pages/manage/index/pass.vue' /* webpackChunkName: "pages/manage/index/pass" */))
const _2deb69fd = () => interopDefault(import('../pages/manage/index/payments.vue' /* webpackChunkName: "pages/manage/index/payments" */))
const _2b57100a = () => interopDefault(import('../pages/manage/index/product-categories.vue' /* webpackChunkName: "pages/manage/index/product-categories" */))
const _eff98e98 = () => interopDefault(import('../pages/manage/index/products.vue' /* webpackChunkName: "pages/manage/index/products" */))
const _7ad9fa70 = () => interopDefault(import('../pages/manage/index/promo-codes.vue' /* webpackChunkName: "pages/manage/index/promo-codes" */))
const _3afc8191 = () => interopDefault(import('../pages/manage/index/purchase-orders.vue' /* webpackChunkName: "pages/manage/index/purchase-orders" */))
const _60f46ebe = () => interopDefault(import('../pages/manage/index/reminders.vue' /* webpackChunkName: "pages/manage/index/reminders" */))
const _37bd2fd3 = () => interopDefault(import('../pages/manage/index/resource-groups.vue' /* webpackChunkName: "pages/manage/index/resource-groups" */))
const _42cea685 = () => interopDefault(import('../pages/manage/index/resources.vue' /* webpackChunkName: "pages/manage/index/resources" */))
const _e077af32 = () => interopDefault(import('../pages/manage/index/schedule.vue' /* webpackChunkName: "pages/manage/index/schedule" */))
const _6b6930f0 = () => interopDefault(import('../pages/manage/index/segments.vue' /* webpackChunkName: "pages/manage/index/segments" */))
const _4cbcdcf8 = () => interopDefault(import('../pages/manage/index/service-categories.vue' /* webpackChunkName: "pages/manage/index/service-categories" */))
const _61006aae = () => interopDefault(import('../pages/manage/index/services.vue' /* webpackChunkName: "pages/manage/index/services" */))
const _39c67c8e = () => interopDefault(import('../pages/manage/index/sms.vue' /* webpackChunkName: "pages/manage/index/sms" */))
const _5f5ca92e = () => interopDefault(import('../pages/manage/index/stock-take.vue' /* webpackChunkName: "pages/manage/index/stock-take" */))
const _f69f50f2 = () => interopDefault(import('../pages/manage/index/suppliers.vue' /* webpackChunkName: "pages/manage/index/suppliers" */))
const _aa8e000e = () => interopDefault(import('../pages/manage/index/taxes.vue' /* webpackChunkName: "pages/manage/index/taxes" */))
const _049ffcce = () => interopDefault(import('../pages/manage/index/templates.vue' /* webpackChunkName: "pages/manage/index/templates" */))
const _bca75fae = () => interopDefault(import('../pages/manage/index/theme.vue' /* webpackChunkName: "pages/manage/index/theme" */))
const _6c396232 = () => interopDefault(import('../pages/marketing/index.vue' /* webpackChunkName: "pages/marketing/index" */))
const _28ebfb35 = () => interopDefault(import('../pages/marketing/index/index.vue' /* webpackChunkName: "pages/marketing/index/index" */))
const _d58c609a = () => interopDefault(import('../pages/marketing/index/assets/index.vue' /* webpackChunkName: "pages/marketing/index/assets/index" */))
const _bd73006e = () => interopDefault(import('../pages/marketing/index/campaigns/index.vue' /* webpackChunkName: "pages/marketing/index/campaigns/index" */))
const _26aef2a0 = () => interopDefault(import('../pages/marketing/index/mass-email.vue' /* webpackChunkName: "pages/marketing/index/mass-email" */))
const _47bb7e26 = () => interopDefault(import('../pages/marketing/index/mass-sms.vue' /* webpackChunkName: "pages/marketing/index/mass-sms" */))
const _44bcd97e = () => interopDefault(import('../pages/marketing/index/reviews/index.vue' /* webpackChunkName: "pages/marketing/index/reviews/index" */))
const _f2e20910 = () => interopDefault(import('../pages/marketing/index/campaigns/report/_id.vue' /* webpackChunkName: "pages/marketing/index/campaigns/report/_id" */))
const _a3e3feec = () => interopDefault(import('../pages/marketing/index/messages/_channel.vue' /* webpackChunkName: "pages/marketing/index/messages/_channel" */))
const _0dd0db13 = () => interopDefault(import('../pages/message/index.vue' /* webpackChunkName: "pages/message/index" */))
const _47b7673e = () => interopDefault(import('../pages/message/index/_id.vue' /* webpackChunkName: "pages/message/index/_id" */))
const _1e3cdf28 = () => interopDefault(import('../pages/minisite/index.vue' /* webpackChunkName: "pages/minisite/index" */))
const _669f67ab = () => interopDefault(import('../pages/minisite/index/index.vue' /* webpackChunkName: "pages/minisite/index/index" */))
const _3f69df72 = () => interopDefault(import('../pages/minisite/index/booking.vue' /* webpackChunkName: "pages/minisite/index/booking" */))
const _13f40018 = () => interopDefault(import('../pages/minisite/index/business-info.vue' /* webpackChunkName: "pages/minisite/index/business-info" */))
const _2db4bfb9 = () => interopDefault(import('../pages/minisite/index/contact.vue' /* webpackChunkName: "pages/minisite/index/contact" */))
const _42dc349f = () => interopDefault(import('../pages/minisite/index/custom-pages.vue' /* webpackChunkName: "pages/minisite/index/custom-pages" */))
const _75023a95 = () => interopDefault(import('../pages/minisite/index/customer.vue' /* webpackChunkName: "pages/minisite/index/customer" */))
const _0d7e2f8a = () => interopDefault(import('../pages/minisite/index/domain.vue' /* webpackChunkName: "pages/minisite/index/domain" */))
const _42c63232 = () => interopDefault(import('../pages/minisite/index/embed.vue' /* webpackChunkName: "pages/minisite/index/embed" */))
const _a9adac2a = () => interopDefault(import('../pages/minisite/index/gallery.vue' /* webpackChunkName: "pages/minisite/index/gallery" */))
const _29669b7d = () => interopDefault(import('../pages/minisite/index/gift-cards.vue' /* webpackChunkName: "pages/minisite/index/gift-cards" */))
const _5922c130 = () => interopDefault(import('../pages/minisite/index/hours.vue' /* webpackChunkName: "pages/minisite/index/hours" */))
const _2ddce1d4 = () => interopDefault(import('../pages/minisite/index/menu.vue' /* webpackChunkName: "pages/minisite/index/menu" */))
const _7f47e7be = () => interopDefault(import('../pages/minisite/index/minimise-gaps.vue' /* webpackChunkName: "pages/minisite/index/minimise-gaps" */))
const _5dd73341 = () => interopDefault(import('../pages/minisite/index/new-customers.vue' /* webpackChunkName: "pages/minisite/index/new-customers" */))
const _71de6e76 = () => interopDefault(import('../pages/minisite/index/passes.vue' /* webpackChunkName: "pages/minisite/index/passes" */))
const _22c48a64 = () => interopDefault(import('../pages/minisite/index/payments.vue' /* webpackChunkName: "pages/minisite/index/payments" */))
const _0d78e611 = () => interopDefault(import('../pages/minisite/index/preferences.vue' /* webpackChunkName: "pages/minisite/index/preferences" */))
const _7d5f1a34 = () => interopDefault(import('../pages/minisite/index/reviews.vue' /* webpackChunkName: "pages/minisite/index/reviews" */))
const _113788c2 = () => interopDefault(import('../pages/minisite/index/script.vue' /* webpackChunkName: "pages/minisite/index/script" */))
const _d124cc14 = () => interopDefault(import('../pages/minisite/index/seo.vue' /* webpackChunkName: "pages/minisite/index/seo" */))
const _2c37b837 = () => interopDefault(import('../pages/minisite/index/site-notice.vue' /* webpackChunkName: "pages/minisite/index/site-notice" */))
const _579ca338 = () => interopDefault(import('../pages/minisite/index/social.vue' /* webpackChunkName: "pages/minisite/index/social" */))
const _109d25e0 = () => interopDefault(import('../pages/minisite/index/terms.vue' /* webpackChunkName: "pages/minisite/index/terms" */))
const _d06b3dbc = () => interopDefault(import('../pages/minisite/index/theme.vue' /* webpackChunkName: "pages/minisite/index/theme" */))
const _04bf372c = () => interopDefault(import('../pages/minisite/index/pages/site-notice.vue' /* webpackChunkName: "pages/minisite/index/pages/site-notice" */))
const _50d8dd25 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _35e6adda = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _2419e07e = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _7e4f9081 = () => interopDefault(import('../pages/report/index/index.vue' /* webpackChunkName: "pages/report/index/index" */))
const _e8f29770 = () => interopDefault(import('../pages/report/index/_type.vue' /* webpackChunkName: "pages/report/index/_type" */))
const _6705b915 = () => interopDefault(import('../pages/roster/index.vue' /* webpackChunkName: "pages/roster/index" */))
const _4d27233e = () => interopDefault(import('../pages/schedule/index.vue' /* webpackChunkName: "pages/schedule/index" */))
const _34c61724 = () => interopDefault(import('../pages/schedule/index/day.vue' /* webpackChunkName: "pages/schedule/index/day" */))
const _7079c5e4 = () => interopDefault(import('../pages/schedule/index/grid.vue' /* webpackChunkName: "pages/schedule/index/grid" */))
const _2823981a = () => interopDefault(import('../pages/schedule/index/timetable.vue' /* webpackChunkName: "pages/schedule/index/timetable" */))
const _386ad392 = () => interopDefault(import('../pages/schedule/index/week.vue' /* webpackChunkName: "pages/schedule/index/week" */))
const _89f33018 = () => interopDefault(import('../pages/unauthorised.vue' /* webpackChunkName: "pages/unauthorised" */))
const _59aa0dd3 = () => interopDefault(import('../pages/update/index.vue' /* webpackChunkName: "pages/update/index" */))
const _3fd9505d = () => interopDefault(import('../pages/waitlist/index.vue' /* webpackChunkName: "pages/waitlist/index" */))
const _c6b2c058 = () => interopDefault(import('../pages/account/inactive.vue' /* webpackChunkName: "pages/account/inactive" */))
const _7518e6b1 = () => interopDefault(import('../pages/account/subscribe.vue' /* webpackChunkName: "pages/account/subscribe" */))
const _e34d3e10 = () => interopDefault(import('../pages/account/unsubscribe.vue' /* webpackChunkName: "pages/account/unsubscribe" */))
const _eb2915bc = () => interopDefault(import('../pages/account/verify.vue' /* webpackChunkName: "pages/account/verify" */))
const _25ec3bd9 = () => interopDefault(import('../pages/admin-user/edit.vue' /* webpackChunkName: "pages/admin-user/edit" */))
const _f12d101a = () => interopDefault(import('../pages/appointment/create.vue' /* webpackChunkName: "pages/appointment/create" */))
const _47e61b56 = () => interopDefault(import('../pages/appointment/list.vue' /* webpackChunkName: "pages/appointment/list" */))
const _5589c0d5 = () => interopDefault(import('../pages/appointmentStatusType/edit.vue' /* webpackChunkName: "pages/appointmentStatusType/edit" */))
const _782d83ce = () => interopDefault(import('../pages/brand/edit.vue' /* webpackChunkName: "pages/brand/edit" */))
const _52cd120a = () => interopDefault(import('../pages/cancellationReason/edit.vue' /* webpackChunkName: "pages/cancellationReason/edit" */))
const _340c62e7 = () => interopDefault(import('../pages/customer/edit/index.vue' /* webpackChunkName: "pages/customer/edit/index" */))
const _78c46010 = () => interopDefault(import('../pages/customer/list.vue' /* webpackChunkName: "pages/customer/list" */))
const _b954b1cc = () => interopDefault(import('../pages/customer/panel.vue' /* webpackChunkName: "pages/customer/panel" */))
const _5d86aed2 = () => interopDefault(import('../pages/customField/edit.vue' /* webpackChunkName: "pages/customField/edit" */))
const _78d2b006 = () => interopDefault(import('../pages/employee/create.vue' /* webpackChunkName: "pages/employee/create" */))
const _3fc33a94 = () => interopDefault(import('../pages/employee/edit.vue' /* webpackChunkName: "pages/employee/edit" */))
const _8754ee46 = () => interopDefault(import('../pages/message/email.vue' /* webpackChunkName: "pages/message/email" */))
const _4193ea5a = () => interopDefault(import('../pages/message/sms.vue' /* webpackChunkName: "pages/message/sms" */))
const _63f32ac6 = () => interopDefault(import('../pages/order/list.vue' /* webpackChunkName: "pages/order/list" */))
const _09331d11 = () => interopDefault(import('../pages/pass/edit.vue' /* webpackChunkName: "pages/pass/edit" */))
const _b9095c1e = () => interopDefault(import('../pages/product/edit.vue' /* webpackChunkName: "pages/product/edit" */))
const _7f477293 = () => interopDefault(import('../pages/productCategory/edit.vue' /* webpackChunkName: "pages/productCategory/edit" */))
const _d8871492 = () => interopDefault(import('../pages/promo-code/edit.vue' /* webpackChunkName: "pages/promo-code/edit" */))
const _d9ad22da = () => interopDefault(import('../pages/purchaseOrder/edit.vue' /* webpackChunkName: "pages/purchaseOrder/edit" */))
const _366d6934 = () => interopDefault(import('../pages/resource/edit.vue' /* webpackChunkName: "pages/resource/edit" */))
const _6400bccc = () => interopDefault(import('../pages/resourceType/edit.vue' /* webpackChunkName: "pages/resourceType/edit" */))
const _38a9bb68 = () => interopDefault(import('../pages/role/edit.vue' /* webpackChunkName: "pages/role/edit" */))
const _131a67a6 = () => interopDefault(import('../pages/segment/edit.vue' /* webpackChunkName: "pages/segment/edit" */))
const _763af6eb = () => interopDefault(import('../pages/service/edit.vue' /* webpackChunkName: "pages/service/edit" */))
const _761104e6 = () => interopDefault(import('../pages/serviceCategory/edit.vue' /* webpackChunkName: "pages/serviceCategory/edit" */))
const _68075bb8 = () => interopDefault(import('../pages/stock-take/edit.vue' /* webpackChunkName: "pages/stock-take/edit" */))
const _5e2ac936 = () => interopDefault(import('../pages/supplier/edit.vue' /* webpackChunkName: "pages/supplier/edit" */))
const _daf2bb96 = () => interopDefault(import('../pages/tax/edit.vue' /* webpackChunkName: "pages/tax/edit" */))
const _c779cb70 = () => interopDefault(import('../pages/template/edit.vue' /* webpackChunkName: "pages/template/edit" */))
const _4d8f857c = () => interopDefault(import('../pages/customer/form/print.vue' /* webpackChunkName: "pages/customer/form/print" */))
const _4d2ba126 = () => interopDefault(import('../pages/customer/form/view.vue' /* webpackChunkName: "pages/customer/form/view" */))
const _49ac2c06 = () => interopDefault(import('../pages/marketing/campaigns/edit.vue' /* webpackChunkName: "pages/marketing/campaigns/edit" */))
const _0e9e0d9c = () => interopDefault(import('../pages/marketing/reviews/review.vue' /* webpackChunkName: "pages/marketing/reviews/review" */))
const _d7bdfabe = () => interopDefault(import('../pages/roster/print/month.vue' /* webpackChunkName: "pages/roster/print/month" */))
const _34b175e3 = () => interopDefault(import('../pages/roster/print/week.vue' /* webpackChunkName: "pages/roster/print/week" */))
const _1479741b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a95233c8 = () => interopDefault(import('../pages/appointment/attendee/_id.vue' /* webpackChunkName: "pages/appointment/attendee/_id" */))
const _32ce287e = () => interopDefault(import('../pages/customer/credit/_id.vue' /* webpackChunkName: "pages/customer/credit/_id" */))
const _00e1906e = () => interopDefault(import('../pages/customer/form/_id.vue' /* webpackChunkName: "pages/customer/form/_id" */))
const _54c8de65 = () => interopDefault(import('../pages/customer/giftCard/_id.vue' /* webpackChunkName: "pages/customer/giftCard/_id" */))
const _4fdbc887 = () => interopDefault(import('../pages/customer/loyalty/_id.vue' /* webpackChunkName: "pages/customer/loyalty/_id" */))
const _d43c1bd4 = () => interopDefault(import('../pages/customer/pass/_id.vue' /* webpackChunkName: "pages/customer/pass/_id" */))
const _550bca52 = () => interopDefault(import('../pages/message/chat/_id.vue' /* webpackChunkName: "pages/message/chat/_id" */))
const _45f1e953 = () => interopDefault(import('../pages/appointment/_id.vue' /* webpackChunkName: "pages/appointment/_id" */))
const _eb370dc2 = () => interopDefault(import('../pages/custom-page/_id.vue' /* webpackChunkName: "pages/custom-page/_id" */))
const _4c656960 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _f168fc94 = () => interopDefault(import('../pages/form/_id.vue' /* webpackChunkName: "pages/form/_id" */))
const _614101ea = () => interopDefault(import('../pages/integration/_id/index.vue' /* webpackChunkName: "pages/integration/_id/index" */))
const _631a5ec2 = () => interopDefault(import('../pages/order/_id.vue' /* webpackChunkName: "pages/order/_id" */))
const _896c73f6 = () => interopDefault(import('../pages/waitlist/_id.vue' /* webpackChunkName: "pages/waitlist/_id" */))
const _69c3423a = () => interopDefault(import('../pages/integration/_id/connect.vue' /* webpackChunkName: "pages/integration/_id/connect" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _21f1248e,
    meta: {"grants":{"account":["edit"]},"name":"Account"},
    children: [{
      path: "",
      component: _b9a1f508,
      meta: {},
      name: "account-index"
    }, {
      path: "billing",
      component: _f036f3b6,
      meta: {"grants":{"account":["edit"]},"name":"Plan"},
      name: "account-index-billing"
    }, {
      path: "calendar",
      component: _91bd6af8,
      meta: {"grants":{"calendarFeed":["view"]},"name":"Calendar feeds"},
      name: "account-index-calendar"
    }, {
      path: "devices",
      component: _27790f87,
      meta: {"grants":{"account":["edit"]},"name":"Devices"},
      name: "account-index-devices"
    }, {
      path: "exports",
      component: _0693c4e9,
      meta: {"grants":{"export":["view"]},"name":"Exports"},
      name: "account-index-exports"
    }, {
      path: "integrations",
      component: _683d9636,
      meta: {"grants":{"integration":["view"]},"name":"Integrations"},
      name: "account-index-integrations"
    }, {
      path: "policy",
      component: _10762410,
      meta: {},
      name: "account-index-policy"
    }, {
      path: "privacy",
      component: _47ff8e9c,
      meta: {"grants":{"account":["edit"]},"name":"Privacy"},
      name: "account-index-privacy"
    }, {
      path: "referral",
      component: _482b8363,
      meta: {"grants":{"account":["edit"]},"name":"Referrals"},
      name: "account-index-referral"
    }, {
      path: "security",
      component: _075a14f4,
      meta: {"grants":{"account":["edit"]},"name":"Security"},
      name: "account-index-security"
    }, {
      path: "sessions",
      component: _b76b4f3a,
      meta: {"grants":{"account":["session"]},"name":"Sessions"},
      name: "account-index-sessions"
    }, {
      path: "storage",
      component: _6310a625,
      meta: {"name":"Sync"},
      name: "account-index-storage"
    }, {
      path: "users",
      component: _2ece409c,
      meta: {"grants":{"account":["edit"]},"name":"Users"},
      name: "account-index-users",
      children: [{
        path: "owner",
        component: _9d736bd4,
        meta: {"grants":{"account":["edit"]},"name":"Roles"},
        name: "account-index-users-owner"
      }, {
        path: "roles",
        component: _75685dc0,
        meta: {"grants":{"account":["edit"]},"name":"Roles"},
        name: "account-index-users-roles"
      }, {
        path: "users",
        component: _74fd9a2a,
        meta: {"grants":{"employee":["edit"]},"name":"Users"},
        name: "account-index-users-users"
      }]
    }]
  }, {
    path: "/alerts",
    component: _2fea0e21,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "alerts"
  }, {
    path: "/auth",
    component: _6703e7b2,
    meta: {"layout":"auth","props":{"location":{"default":null}}},
    name: "auth",
    children: [{
      path: "find",
      component: _16da7bae,
      meta: {"layout":"auth"},
      name: "auth-index-find",
      children: [{
        path: "accounts",
        component: _0e5420bc,
        meta: {"layout":"auth","props":{"accounts":{"default":null}}},
        name: "auth-index-find-index-accounts"
      }, {
        path: "code",
        component: _0de70643,
        meta: {"layout":"auth","props":{"accounts":{"default":null}}},
        name: "auth-index-find-index-code"
      }, {
        path: "domain",
        component: _5bf6d21a,
        meta: {},
        name: "auth-index-find-index-domain"
      }, {
        path: "email",
        component: _7329f3b6,
        meta: {"layout":"auth"},
        name: "auth-index-find-index-email"
      }]
    }, {
      path: "integration",
      component: _67df6c8c,
      meta: {"layout":"auth"},
      name: "auth-index-integration",
      children: [{
        path: ":guid?/connect",
        component: _917124a4,
        meta: {},
        name: "auth-index-integration-index-guid-connect"
      }]
    }, {
      path: "login",
      component: _6e8cfd62,
      meta: {"layout":"auth","props":{"location":{"required":true}}},
      name: "auth-index-login",
      children: [{
        path: "code",
        component: _0ff7cedd,
        meta: {"layout":"auth","props":{"location":{"required":true}}},
        name: "auth-index-login-index-code"
      }, {
        path: "email",
        component: _999b8348,
        meta: {"layout":"auth","props":{"location":{"required":true}}},
        name: "auth-index-login-index-email"
      }, {
        path: "password",
        component: _72eea88b,
        meta: {"layout":"auth","props":{"location":{"required":true}}},
        name: "auth-index-login-index-password"
      }]
    }, {
      path: "pin",
      component: _051be6b8,
      meta: {},
      name: "auth-index-pin"
    }, {
      path: "reset",
      component: _c423f096,
      meta: {"layout":"auth","props":{"location":{"required":true}}},
      name: "auth-index-reset",
      children: [{
        path: "confirm",
        component: _4cf30434,
        meta: {"layout":"auth","props":{"location":{"required":true}}},
        name: "auth-index-reset-index-confirm"
      }, {
        path: "email",
        component: _6825e042,
        meta: {"layout":"auth","props":{"location":{"required":true}}},
        name: "auth-index-reset-index-email"
      }, {
        path: "success",
        component: _79667da9,
        meta: {"layout":"auth"},
        name: "auth-index-reset-index-success"
      }]
    }, {
      path: "switch",
      component: _5e578a3e,
      meta: {"layout":"auth"},
      name: "auth-index-switch"
    }, {
      path: "token",
      component: _a6a5f348,
      meta: {"layout":"auth"},
      name: "auth-index-token"
    }, {
      path: "verify",
      component: _b0163cb4,
      meta: {"layout":"auth","props":{"location":{"required":true}}},
      name: "auth-index-verify"
    }]
  }, {
    path: "/customer",
    component: _68dea230,
    meta: {"grants":{"customer":["view"]}},
    name: "customer"
  }, {
    path: "/demo",
    component: _00d23a2d,
    meta: {"layout":"full"},
    name: "demo"
  }, {
    path: "/elements",
    component: _6fc6a4be,
    meta: {},
    name: "elements"
  }, {
    path: "/export",
    component: _5afa7efe,
    meta: {"inject":["panel"],"props":{"route":{"required":true},"presets":{}},"panel":true},
    name: "export"
  }, {
    path: "/history",
    component: _37619940,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "history"
  }, {
    path: "/maintenance",
    component: _660b25c8,
    meta: {"layout":"full"},
    name: "maintenance"
  }, {
    path: "/manage",
    component: _77e45f6f,
    meta: {"name":"Manage"},
    children: [{
      path: "",
      component: _708156b2,
      meta: {},
      name: "manage-index"
    }, {
      path: "appointment-status-types",
      component: _505f259c,
      meta: {"grants":{"statusType":["edit"]},"name":"Appointment status types"},
      name: "manage-index-appointment-status-types"
    }, {
      path: "brands",
      component: _6e8f31c8,
      meta: {"grants":{"brand":["edit"]},"name":"Brands"},
      name: "manage-index-brands"
    }, {
      path: "cancellation-reasons",
      component: _652c5ab5,
      meta: {"grants":{"reservationCancellationType":["edit"]},"name":"Cancellation Reasons"},
      name: "manage-index-cancellation-reasons"
    }, {
      path: "charges",
      component: _fb4c1cc2,
      meta: {"grants":{"chargeModel":["edit"]},"name":"Charges"},
      name: "manage-index-charges"
    }, {
      path: "custom-fields",
      component: _46ed98f5,
      meta: {"grants":{"customField":["edit"]},"features":["custom-fields"]},
      name: "manage-index-custom-fields"
    }, {
      path: "customer-gift-cards",
      component: _078a8c35,
      meta: {"grants":{"giftCard":["view"]},"features":["gift-cards"],"name":"Gift cards"},
      name: "manage-index-customer-gift-cards"
    }, {
      path: "customer-pass",
      component: _5285e100,
      meta: {"grants":{"customerPass":["edit"]},"features":["passes"],"name":"Customer Passes"},
      name: "manage-index-customer-pass"
    }, {
      path: "details",
      component: _697e13bc,
      meta: {"grants":{"account":["edit"]},"name":"Details"},
      name: "manage-index-details"
    }, {
      path: "discounts",
      component: _007fbd32,
      meta: {"grants":{"discountModel":["edit"]},"name":"Discounts"},
      name: "manage-index-discounts"
    }, {
      path: "email",
      component: _1f06047c,
      meta: {"grants":{"account":["edit"]},"name":"Email settings"},
      name: "manage-index-email"
    }, {
      path: "employees",
      component: _e0f7b036,
      meta: {"grants":{"employee":["edit"]},"name":"Employees"},
      name: "manage-index-employees"
    }, {
      path: "forms",
      component: _0d8cc38f,
      meta: {"grants":{"form":["edit"]},"features":["forms"],"name":"Forms"},
      name: "manage-index-forms"
    }, {
      path: "location",
      component: _47057685,
      meta: {"grants":{"account":["edit"]},"name":"Location"},
      name: "manage-index-location"
    }, {
      path: "loyalty-program",
      component: _612bf346,
      meta: {"grants":{"account":["edit"]},"features":["loyalty"],"name":"Account"},
      name: "manage-index-loyalty-program"
    }, {
      path: "pass",
      component: _5c8e6e3e,
      meta: {"grants":{"pass":["edit"]},"features":["passes"],"name":"Passes"},
      name: "manage-index-pass"
    }, {
      path: "payments",
      component: _2deb69fd,
      meta: {"grants":{"paymentModel":["edit"]},"name":"Payments"},
      name: "manage-index-payments"
    }, {
      path: "product-categories",
      component: _2b57100a,
      meta: {"grants":{"productCategory":["edit"]},"name":"Product categories"},
      name: "manage-index-product-categories"
    }, {
      path: "products",
      component: _eff98e98,
      meta: {"grants":{"product":["edit"]},"name":"Products"},
      name: "manage-index-products"
    }, {
      path: "promo-codes",
      component: _7ad9fa70,
      meta: {"grants":{"voucher":["edit"]},"features":["vouchers"],"name":"Promo codes"},
      alias: ["/manage/active-promo-codes","/manage/expired-promo-codes"],
      name: "manage-index-promo-codes"
    }, {
      path: "purchase-orders",
      component: _3afc8191,
      meta: {"grants":{"purchaseOrder":["edit"]},"features":["inventory-control"],"name":"Purchase Orders"},
      name: "manage-index-purchase-orders"
    }, {
      path: "reminders",
      component: _60f46ebe,
      meta: {"grants":{"account":["edit"]},"name":"Reminders"},
      name: "manage-index-reminders"
    }, {
      path: "resource-groups",
      component: _37bd2fd3,
      meta: {"grants":{"employee":["edit"]},"features":["resources"],"name":"Resource Groups"},
      name: "manage-index-resource-groups"
    }, {
      path: "resources",
      component: _42cea685,
      meta: {},
      name: "manage-index-resources"
    }, {
      path: "schedule",
      component: _e077af32,
      meta: {"grants":{"account":["edit"]},"name":"Schedule"},
      name: "manage-index-schedule"
    }, {
      path: "segments",
      component: _6b6930f0,
      meta: {"grants":{"segment":["edit"]},"features":["segments"],"name":"Segments"},
      name: "manage-index-segments"
    }, {
      path: "service-categories",
      component: _4cbcdcf8,
      meta: {"grants":{"serviceCategory":["edit"]},"name":"Service categories"},
      name: "manage-index-service-categories"
    }, {
      path: "services",
      component: _61006aae,
      meta: {"grants":{"service":["edit"]},"name":"Services"},
      name: "manage-index-services"
    }, {
      path: "sms",
      component: _39c67c8e,
      meta: {"grants":{"account":["edit"]},"name":"SMS settings"},
      name: "manage-index-sms"
    }, {
      path: "stock-take",
      component: _5f5ca92e,
      meta: {"grants":{"stockTake":["edit"]},"features":["inventory-control"],"name":"Stock takes"},
      name: "manage-index-stock-take"
    }, {
      path: "suppliers",
      component: _f69f50f2,
      meta: {"grants":{"supplier":["edit"]},"name":"Suppliers"},
      name: "manage-index-suppliers"
    }, {
      path: "taxes",
      component: _aa8e000e,
      meta: {"grants":{"tax":["edit"]},"name":"Taxes"},
      name: "manage-index-taxes"
    }, {
      path: "templates",
      component: _049ffcce,
      meta: {"grants":{"account":["edit"]},"name":"Email templates"},
      name: "manage-index-templates"
    }, {
      path: "theme",
      component: _bca75fae,
      meta: {"grants":{"account":["edit"]},"name":"Location"},
      name: "manage-index-theme"
    }, {
      path: "/manage/custom-fields-customer",
      component: _46ed98f5,
      meta: {"grants":{"customField":["edit"]},"features":["custom-fields"]},
      name: "Customer"
    }, {
      path: "/manage/custom-fields-reservation",
      component: _46ed98f5,
      meta: {"grants":{"customField":["edit"]},"features":["custom-fields"]},
      name: "Reservation"
    }, {
      path: "/manage/custom-fields-reservationCustomer",
      component: _46ed98f5,
      meta: {"grants":{"customField":["edit"]},"features":["custom-fields"]},
      name: "Group customer"
    }, {
      path: "/manage/custom-fields-order",
      component: _46ed98f5,
      meta: {"grants":{"customField":["edit"]},"features":["custom-fields"]},
      name: "Sale"
    }, {
      path: "/manage/custom-fields-employee",
      component: _46ed98f5,
      meta: {"grants":{"customField":["edit"]},"features":["custom-fields"]},
      name: "Employee"
    }, {
      path: "/manage/custom-fields-service",
      component: _46ed98f5,
      meta: {"grants":{"customField":["edit"]},"features":["custom-fields"]},
      name: "Service"
    }, {
      path: "/manage/custom-fields-product",
      component: _46ed98f5,
      meta: {"grants":{"customField":["edit"]},"features":["custom-fields"]},
      name: "Product"
    }]
  }, {
    path: "/marketing",
    component: _6c396232,
    meta: {"grants":{"marketing":["view"]},"name":"Marketing"},
    children: [{
      path: "",
      component: _28ebfb35,
      meta: {},
      name: "marketing-index"
    }, {
      path: "assets",
      component: _d58c609a,
      meta: {"grants":{"marketing":["view"]},"name":"Asset Library"},
      name: "marketing-index-assets"
    }, {
      path: "campaigns",
      component: _bd73006e,
      meta: {"grants":{"marketing":["view"]},"features":["campaigns"],"name":"Campaigns"},
      name: "marketing-index-campaigns"
    }, {
      path: "mass-email",
      component: _26aef2a0,
      meta: {"props":{"route":{}}},
      name: "marketing-index-mass-email"
    }, {
      path: "mass-sms",
      component: _47bb7e26,
      meta: {"props":{"route":{}},"title":"Mass SMS"},
      name: "marketing-index-mass-sms"
    }, {
      path: "reviews",
      component: _44bcd97e,
      meta: {"grants":{"marketing":["view"]},"features":["reviews"],"name":"Reviews"},
      name: "marketing-index-reviews"
    }, {
      path: "campaigns/report/:id?",
      component: _f2e20910,
      meta: {"grants":{"marketing":["report"]},"name":"Campaign report"},
      name: "marketing-index-campaigns-report-id"
    }, {
      path: "messages/:channel?",
      component: _a3e3feec,
      meta: {"grants":{"marketing":["view"]},"name":"Messages"},
      name: "marketing-index-messages-channel"
    }]
  }, {
    path: "/message",
    component: _0dd0db13,
    meta: {"grants":{"account":["edit"]},"name":"Messages"},
    name: "message",
    children: [{
      path: ":id?",
      component: _47b7673e,
      meta: {"props":{"active":{"required":true}}},
      name: "message-index-id"
    }]
  }, {
    path: "/minisite",
    component: _1e3cdf28,
    meta: {"grants":{"account":["edit"]},"name":"Minisite"},
    children: [{
      path: "",
      component: _669f67ab,
      meta: {},
      name: "minisite-index"
    }, {
      path: "booking",
      component: _3f69df72,
      meta: {"grants":{"account":["edit"]},"name":"Mini-site"},
      name: "minisite-index-booking"
    }, {
      path: "business-info",
      component: _13f40018,
      meta: {"grants":{"account":["edit"]},"name":"Theme"},
      name: "minisite-index-business-info"
    }, {
      path: "contact",
      component: _2db4bfb9,
      meta: {"grants":{"account":["edit"]},"name":"Contact info"},
      name: "minisite-index-contact"
    }, {
      path: "custom-pages",
      component: _42dc349f,
      meta: {"grants":{"account":["edit"]},"name":"Custom Pages","features":["custom-pages"]},
      name: "minisite-index-custom-pages"
    }, {
      path: "customer",
      component: _75023a95,
      meta: {"grants":{"account":["edit"]},"name":"Customer Account"},
      name: "minisite-index-customer"
    }, {
      path: "domain",
      component: _0d7e2f8a,
      meta: {"grants":{"account":["edit"]},"name":"Domain"},
      name: "minisite-index-domain"
    }, {
      path: "embed",
      component: _42c63232,
      meta: {"grants":{"account":["edit"]},"name":"Settings"},
      name: "minisite-index-embed"
    }, {
      path: "gallery",
      component: _a9adac2a,
      meta: {"grants":{"account":["edit"]},"name":"Gallery"},
      name: "minisite-index-gallery"
    }, {
      path: "gift-cards",
      component: _29669b7d,
      meta: {"grants":{"account":["edit"]},"name":"Gift cards","features":["gift-cards"]},
      name: "minisite-index-gift-cards"
    }, {
      path: "hours",
      component: _5922c130,
      meta: {"grants":{"account":["edit"]},"name":"Business hours"},
      name: "minisite-index-hours"
    }, {
      path: "menu",
      component: _2ddce1d4,
      meta: {"grants":{"account":["edit"]},"name":"Menu","features":["custom-pages"]},
      name: "minisite-index-menu"
    }, {
      path: "minimise-gaps",
      component: _7f47e7be,
      meta: {"grants":{"account":["edit"]},"name":"Minimise gaps"},
      name: "minisite-index-minimise-gaps"
    }, {
      path: "new-customers",
      component: _5dd73341,
      meta: {"grants":{"account":["edit"]},"name":"New customers"},
      name: "minisite-index-new-customers"
    }, {
      path: "passes",
      component: _71de6e76,
      meta: {"grants":{"account":["edit"]},"name":"Passes","features":["passes"]},
      name: "minisite-index-passes"
    }, {
      path: "payments",
      component: _22c48a64,
      meta: {"grants":{"account":["edit"]},"name":"Payments"},
      name: "minisite-index-payments"
    }, {
      path: "preferences",
      component: _0d78e611,
      meta: {"grants":{"account":["edit"]},"name":"Preferences"},
      name: "minisite-index-preferences"
    }, {
      path: "reviews",
      component: _7d5f1a34,
      meta: {"grants":{"account":["edit"]},"name":"Reviews"},
      name: "minisite-index-reviews"
    }, {
      path: "script",
      component: _113788c2,
      meta: {"grants":{"account":["edit"]},"gates":["online-custom-script"],"name":"Custom Script"},
      name: "minisite-index-script"
    }, {
      path: "seo",
      component: _d124cc14,
      meta: {"grants":{"account":["edit"]},"name":"SEO"},
      name: "minisite-index-seo"
    }, {
      path: "site-notice",
      component: _2c37b837,
      meta: {"grants":{"account":["edit"]},"name":"Site notice"},
      name: "minisite-index-site-notice"
    }, {
      path: "social",
      component: _579ca338,
      meta: {"grants":{"account":["edit"]},"name":"Social links"},
      name: "minisite-index-social"
    }, {
      path: "terms",
      component: _109d25e0,
      meta: {"grants":{"account":["edit"]},"name":"Terms"},
      name: "minisite-index-terms"
    }, {
      path: "theme",
      component: _d06b3dbc,
      meta: {"grants":{"account":["edit"]},"name":"Theme"},
      name: "minisite-index-theme"
    }, {
      path: "pages/site-notice",
      component: _04bf372c,
      meta: {"props":{"minisite":{"required":true}},"grants":{"account":["edit"]},"name":"Site notice"},
      name: "minisite-index-pages-site-notice"
    }]
  }, {
    path: "/onboarding",
    component: _50d8dd25,
    meta: {"layout":"full"},
    name: "onboarding"
  }, {
    path: "/order",
    component: _35e6adda,
    meta: {"grants":{"order":["view"]}},
    name: "order"
  }, {
    path: "/report",
    component: _2419e07e,
    meta: {"grants":{"report":["view"]},"name":"Reports"},
    children: [{
      path: "",
      component: _7e4f9081,
      meta: {"props":{"pages":{"required":true}}},
      name: "report-index"
    }, {
      path: ":type",
      component: _e8f29770,
      meta: {"grants":{"report":["view"]},"name":"Report"},
      name: "report-index-type"
    }]
  }, {
    path: "/roster",
    component: _6705b915,
    meta: {"grants":{"roster":["view"]}},
    name: "roster"
  }, {
    path: "/schedule",
    component: _4d27233e,
    meta: {},
    name: "schedule",
    children: [{
      path: "day",
      component: _34c61724,
      meta: {},
      name: "schedule-index-day"
    }, {
      path: "grid",
      component: _7079c5e4,
      meta: {},
      name: "schedule-index-grid"
    }, {
      path: "timetable",
      component: _2823981a,
      meta: {},
      name: "schedule-index-timetable"
    }, {
      path: "week",
      component: _386ad392,
      meta: {},
      name: "schedule-index-week"
    }]
  }, {
    path: "/unauthorised",
    component: _89f33018,
    meta: {"layout":"auth"},
    name: "unauthorised"
  }, {
    path: "/update",
    component: _59aa0dd3,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "update"
  }, {
    path: "/waitlist",
    component: _3fd9505d,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "waitlist"
  }, {
    path: "/account/inactive",
    component: _c6b2c058,
    meta: {"layout":"full","name":"Inactive account"},
    name: "account-inactive"
  }, {
    path: "/account/subscribe",
    component: _7518e6b1,
    meta: {"layout":"full","name":"Subscribe"},
    name: "account-subscribe"
  }, {
    path: "/account/unsubscribe",
    component: _e34d3e10,
    meta: {"layout":"full","grants":{"account":["edit"]},"name":"Unsubscribe"},
    name: "account-unsubscribe"
  }, {
    path: "/account/verify",
    component: _eb2915bc,
    meta: {"grants":{"account":["edit"]},"name":"Verify"},
    name: "account-verify"
  }, {
    path: "/admin-user/edit",
    component: _25ec3bd9,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "admin-user-edit"
  }, {
    path: "/appointment/create",
    component: _f12d101a,
    meta: {"inject":["panel"],"props":{"route":{"required":true},"presets":{}},"panel":true},
    name: "appointment-create"
  }, {
    path: "/appointment/list",
    component: _47e61b56,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "appointment-list"
  }, {
    path: "/appointmentStatusType/edit",
    component: _5589c0d5,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "appointmentStatusType-edit"
  }, {
    path: "/brand/edit",
    component: _782d83ce,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "brand-edit"
  }, {
    path: "/cancellationReason/edit",
    component: _52cd120a,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "cancellationReason-edit"
  }, {
    path: "/customer/edit",
    component: _340c62e7,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "customer-edit"
  }, {
    path: "/customer/list",
    component: _78c46010,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "customer-list"
  }, {
    path: "/customer/panel",
    component: _b954b1cc,
    meta: {"inject":{"panel":{"default":null}},"props":{"route":{"default":null}},"panel":true},
    name: "customer-panel"
  }, {
    path: "/customField/edit",
    component: _5d86aed2,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "customField-edit"
  }, {
    path: "/employee/create",
    component: _78d2b006,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "employee-create"
  }, {
    path: "/employee/edit",
    component: _3fc33a94,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "employee-edit"
  }, {
    path: "/message/email",
    component: _8754ee46,
    meta: {"inject":["panel"],"props":{"customers":{}},"panel":true},
    name: "message-email"
  }, {
    path: "/message/sms",
    component: _4193ea5a,
    meta: {"inject":["panel"],"props":{"customers":{}},"panel":true},
    name: "message-sms"
  }, {
    path: "/order/list",
    component: _63f32ac6,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "order-list"
  }, {
    path: "/pass/edit",
    component: _09331d11,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "pass-edit"
  }, {
    path: "/product/edit",
    component: _b9095c1e,
    meta: {"inject":["panel"],"props":{"route":{"required":true},"category":{"type":[undefined,undefined,undefined],"default":null}},"panel":true},
    name: "product-edit"
  }, {
    path: "/productCategory/edit",
    component: _7f477293,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "productCategory-edit"
  }, {
    path: "/promo-code/edit",
    component: _d8871492,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "promo-code-edit"
  }, {
    path: "/purchaseOrder/edit",
    component: _d9ad22da,
    meta: {"inject":["panel"],"props":{},"panel":true},
    name: "purchaseOrder-edit"
  }, {
    path: "/resource/edit",
    component: _366d6934,
    meta: {"inject":["panel"],"props":{},"panel":true},
    name: "resource-edit"
  }, {
    path: "/resourceType/edit",
    component: _6400bccc,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "resourceType-edit"
  }, {
    path: "/role/edit",
    component: _38a9bb68,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "role-edit"
  }, {
    path: "/segment/edit",
    component: _131a67a6,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "segment-edit"
  }, {
    path: "/service/edit",
    component: _763af6eb,
    meta: {"inject":["panel"],"props":{"route":{"required":true},"category":{"type":[undefined,undefined],"default":null}},"panel":true},
    name: "service-edit"
  }, {
    path: "/serviceCategory/edit",
    component: _761104e6,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "serviceCategory-edit"
  }, {
    path: "/stock-take/edit",
    component: _68075bb8,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "stock-take-edit"
  }, {
    path: "/supplier/edit",
    component: _5e2ac936,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "supplier-edit"
  }, {
    path: "/tax/edit",
    component: _daf2bb96,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "tax-edit"
  }, {
    path: "/template/edit",
    component: _c779cb70,
    meta: {"layout":"full"},
    name: "template-edit"
  }, {
    path: "/customer/form/print",
    component: _4d8f857c,
    meta: {"layout":"print"},
    name: "customer-form-print"
  }, {
    path: "/customer/form/view",
    component: _4d2ba126,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "customer-form-view"
  }, {
    path: "/marketing/campaigns/edit",
    component: _49ac2c06,
    meta: {"inject":["panel"],"props":{"route":{"required":true},"customers":{"default":null}},"panel":true},
    name: "marketing-campaigns-edit"
  }, {
    path: "/marketing/reviews/review",
    component: _0e9e0d9c,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "marketing-reviews-review"
  }, {
    path: "/roster/print/month",
    component: _d7bdfabe,
    meta: {"layout":"print","grants":{"roster":["view"]}},
    name: "roster-print-month"
  }, {
    path: "/roster/print/week",
    component: _34b175e3,
    meta: {"layout":"print","grants":{"roster":["view"]}},
    name: "roster-print-week"
  }, {
    path: "/",
    component: _1479741b,
    meta: {},
    name: "index"
  }, {
    path: "/appointment/attendee/:id?",
    component: _a95233c8,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "appointment-attendee-id"
  }, {
    path: "/customer/credit/:id?",
    component: _32ce287e,
    meta: {"props":{"route":{"required":true}},"panel":true},
    name: "customer-credit-id"
  }, {
    path: "/customer/form/:id?",
    component: _00e1906e,
    meta: {"inject":["panel"],"props":{"route":{"required":true},"defaults":{"default":null}},"panel":true},
    name: "customer-form-id"
  }, {
    path: "/customer/giftCard/:id?",
    component: _54c8de65,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "customer-giftCard-id"
  }, {
    path: "/customer/loyalty/:id?",
    component: _4fdbc887,
    meta: {"props":{"route":{"required":true}},"panel":true},
    name: "customer-loyalty-id"
  }, {
    path: "/customer/pass/:id?",
    component: _d43c1bd4,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "customer-pass-id"
  }, {
    path: "/message/chat/:id?",
    component: _550bca52,
    meta: {"inject":{"panel":{"default":null}},"props":{"route":{"default":null}},"panel":true},
    name: "message-chat-id"
  }, {
    path: "/appointment/:id?",
    component: _45f1e953,
    meta: {"inject":["panel"],"props":{"route":{"required":true},"panel":{"required":true},"focused":{"required":true}},"panel":true},
    name: "appointment-id"
  }, {
    path: "/custom-page/:id?",
    component: _eb370dc2,
    meta: {"layout":"full","features":["custom-pages"]},
    name: "custom-page-id"
  }, {
    path: "/customer/:id",
    component: _4c656960,
    meta: {},
    name: "customer-id"
  }, {
    path: "/form/:id?",
    component: _f168fc94,
    meta: {"layout":"full"},
    name: "form-id"
  }, {
    path: "/integration/:id",
    component: _614101ea,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "integration-id"
  }, {
    path: "/order/:id",
    component: _631a5ec2,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "order-id"
  }, {
    path: "/waitlist/:id",
    component: _896c73f6,
    meta: {"inject":["panel"],"props":{"route":{"required":true}},"panel":true},
    name: "waitlist-id"
  }, {
    path: "/integration/:id?/connect",
    component: _69c3423a,
    meta: {"layout":"full"},
    name: "integration-id-connect"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
