<template>
  <overlay-scrollbars
    :ref="uuid"
    v-bind="props"
    :class="[
      uuid,
      baseClass,
      allowDrag ? (active ? 'cursor-grabbing select-none' : 'cursor-grab') : 'cursor-default'
    ]"
    @mousedown.native="mouseDownHandler"
    @mousemove.native="mouseMoveHandler"
    @mouseup.native="mouseUpHandler"
  >
    <div :class="containerClass">
      <slot />
    </div>
  </overlay-scrollbars>
</template>

<script>
import 'overlayscrollbars/css/OverlayScrollbars.css'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'

export default {
  name: 'OScroll',
  components: {
    'overlay-scrollbars': OverlayScrollbarsComponent
  },
  props: {
    yAxis: {
      type: Boolean,
      default: true
    },
    xAxis: {
      type: Boolean,
      default: false
    },
    allowDrag: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: 'h-full'
    },
    baseClass: {
      type: String,
      default: 'h-full max-h-full w-full max-w-full'
    },
    options: {
      type: Object,
      default: () => {}
    },
    callbacks: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      active: false,
      pos: { top: 0, left: 0, x: 0, y: 0 }
    }
  },
  computed: {
    props () {
      const props = {
        options: {
          overflowBehavior: {
            x: this.xAxis ? 'scroll' : 'hidden',
            y: this.yAxis ? 'scroll' : 'hidden'
          },
          scrollbars: {
            ...this.options,
            autoHide: 'leave'
          },
          callbacks: {
            ...this.callbacks
          }
        }
      }

      return props
    },
    container () {
      return document.querySelector(`.${this.uuid} .os-viewport`)
    }
  },
  errorCaptured (error) {
    if (error.message.match(/ResizeObserver loop limit exceeded/)) {
      return false
    }
  },
  beforeCreate () {
    this.uuid = 'scroll-' + this._uid.toString()
  },
  methods: {
    mouseDownHandler (e) {
      this.$nextTick(() => {
        if (this.allowDrag) {
          const container = this.container

          this.pos = {
            startx: e.clientX + container.scrollLeft,
            starty: e.clientY + container.scrollTop,
            diffx: 0,
            diffy: 0
          }

          this.active = true

          this.$emit('scroll', true)
        }
      })
    },
    mouseMoveHandler (e) {
      const { active, container } = this

      if (active && this.allowDrag) {
        this.pos.diffx = (this.pos.startx - (e.clientX + container.scrollLeft))
        this.pos.diffy = (this.pos.starty - (e.clientY + container.scrollTop))

        const x = container.scrollLeft + this.pos.diffx
        const y = container.scrollTop + this.pos.diffy

        this.$nextTick(() => {
          this.scroll({ x, y })
        })
      }
    },
    mouseUpHandler (e) {
      if (this.active && this.allowDrag) {
        this.active = false

        const x = this.container.scrollLeft + this.pos.diffx
        const y = this.container.scrollTop + this.pos.diffy

        this.$nextTick(() => {
          this.scroll({ x, y })
          this.$emit('scroll', false)
        })
      }
    },
    scroll (options, duration = 0, easing, callback) {
      const instance = this.$refs[this.uuid]?.osInstance()

      if (instance) {
        return instance.scroll(options, duration, easing, callback)
      }
    },
    position () {
      const instance = this.$refs[this.uuid]?.osInstance()

      if (instance) {
        return instance.scroll()
      }
    },
    update () {
      const instance = this.$refs[this.uuid]?.osInstance()

      if (instance) {
        return instance.update()
      }
    },
    refresh () {
      this.$refs[this.uuid].update()
    }
  }
}
</script>
