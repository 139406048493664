
import localforage from 'localforage'
import Cookies from 'js-cookie'

import md5 from 'md5'

import { v4 as uuid } from 'uuid'

import Vue from 'vue'

import * as API from '~/api'

import Themes from '@/components/layout/themes'

class Account {
  constructor () {
    this.guid = uuid()

    this.info = null
    this.locked = false
    this.attempts = 0
    this.history = '/'
    this.panel = 'panel'
    this.home = '/'
    this.sort = {}
    this.scheduleView = '/schedule/day'

    this.created_at = new Date()
    this.updated_at = new Date()
  }

  setInfo (data) {
    this.info = data
    this.updated_at = new Date()
  }
}

export default (context, inject) => {
  const plugin = {
    /**
     * Initialize the plugin and clear any old accounts that are no longer valid
     */
    init () {
      Cookies.set('auth.client_id', this.clientId)
      Cookies.set('auth.scope', this.scopes.join(' '))

      API.Model.serverRequest = context.req

      this.clear()
    },

    /**
     * Login a new user, add the account and sync data
     * @param {function} callback
     */
    async login (callback) {
      this.setReady(false)

      // New empty account
      const account = new Account()

      // Set active account cookie for initial login
      context.store.commit('auth/setActive', account.guid)

      try {
        // Login using credentials
        await callback()

        // Fetch account info
        const info = await API.Auth.info()
        account.setInfo(info)

        const accounts = context.store.state.auth.accounts
        const id = account.info.employee.idEmployee

        const existing = Object.values(accounts).find(a => a.info.employee.idEmployee === id)

        if (existing) {
          // Remove existing account
          context.store.commit('auth/removeAccount', existing)
        }

        // Add account to store
        this.addAccount(account)

        // Set the account to active
        this.setActive(account)

        this.syncActive()
        // this.setReady(true)

        // context.redirect('/')

        this.redirect()
      } catch (error) {
        this.setActive(null)
        this.setReady(true)
        console.log(error)
        throw error
      }
    },

    async passwordLogin ({ idLocation, email, password }) {
      return await this.login(() => API.Auth.login({ oauth_client: this.clientId, location: idLocation, email, password }))
    },

    async codeLogin ({ idLocation, email, token, code }) {
      return await this.login(() => API.Auth.codeLogin({ oauth_client: this.clientId, location: idLocation, email, token, code }))
    },

    async tokenLogin ({ token }) {
      return await this.login(() => API.Auth.tokenLogin({ oauth_client: this.clientId, token }))
    },

    async requestCode ({ idLocation, email }) {
      return await API.Auth.requestCode({ oauth_client: this.clientId, location: idLocation, email })
    },

    /* async forgotDomain ({ idLocation, email, token, code }) {
      return await API.Auth.forgotDomain({ oauth_client: this.clientId, location: idLocation, email, token, code })
    }, */

    async find ({ email, token, code }) {
      return await API.Auth.accounts({ oauth_client: this.clientId, email, token, code })
    },

    async resetPassword ({ idLocation, email, password, confirm, token, code }) {
      return await API.Auth.resetPassword({ oauth_client: this.clientId, location: idLocation, email, password, confirm, token, code })
    },

    async checkPin ({ pin }) {
      return await API.Auth.checkPin({ pin })
    },

    async logout ({ revokeTokens = true } = {}) {
      const account = this.active

      if (revokeTokens) {
        try {
          const success = await API.Auth.logout()
        } catch (e) {
          this.$sentry.captureException(e)
        }
      }

      this.setActive(null)

      if (account) {
        context.store.commit('auth/removeAccount', account)
        context.redirect('/auth/login/email')
      }
    },

    lock () {
      context.store.commit('auth/setLock', { locked: true })
    },

    async unlock ({ pin, override = false, sync = false } = {}) {
      const employee = this.active.info.employee
      const pinHash = employee.pinHash
      const idEmployee = employee.idEmployee

      let correct = override || pinHash === md5(pin + idEmployee + 'e475a4a8df94')

      if (!correct) {
        try {
          correct = await this.checkPin({ pin })
        } catch (error) {
          correct = false
        }
      }

      if (correct) {
        context.store.commit('auth/setLock', { locked: false })

        if (sync) {
          this.syncActive()
        }

        this.setAttempts(0)

        this.redirect()
      } else {
        throw new Error('Invalid pin')
      }
    },

    switch () {
      context.app.router.push('/auth/switch')
    },

    redirect () {
      const redirect = context.store.state.auth.auth.redirect

      if (redirect) {
        context.redirect(redirect)
        context.store.commit('auth/set', { key: 'redirect', value: null })
      } else {
        context.redirect(this.active?.history || '/')
      }
    },

    addAccount (account) {
      const accounts = context.store.state.auth.accounts
      const accountIDs = Object.values(accounts).map(account => account.info.employee.idEmployee)

      const ID = account.info.employee.idEmployee

      if (accountIDs.includes(ID)) {
        // const error = { errors: { id: ['Account already logged in'] } }
        // throw error
      }

      context.store.commit('auth/addAccount', account)

      return true
    },

    async fetchInfo () {
      try {
        const info = await API.Auth.info()

        if (!context.route.path.includes('/onboarding') && info?.onboarding) {
          context.redirect('/onboarding')
        }

        context.$sentry?.setTags({
          location_name: info?.location?.title,
          location_id: info?.location?.idLocation,
          employee_name: info?.employee?.displayName,
          employee_id: info?.employee?.idEmployee,
          admin_link: `admin.ovatu.dev/location/${info?.location?.idLocation}`
        })

        return info
      } catch (error) {
        console.log(error)
      }
    },

    async fetchInfoOnce () {
      if (!this.info) {
        return await this.fetchInfo()
      }

      return this.info
    },

    async setActive (account, { sync = false } = {}) {
      context.store.commit('auth/setActive', account?.guid || null)

      if (account) {
        this.resetHydration()

        const info = await this.fetchInfo()

        if (info) {
          context.store.commit('auth/setAccountInfo', { info })

          // Sync local storage and set helpscout for active account
          if (sync) {
            this.syncActive()
          }
        }
      }
    },

    /**
     * Validate and sync the active account info
     */
    async syncInfo () {
      const active = this.active

      if (active) {
        const info = await this.fetchInfo()
        const inactive = info?.plan?.isPlanSubscriptionStateInactive

        if (inactive && !info?.adminUser) {
          context.redirect('/account/inactive')

          this.helpscout()

          this.setTheme(info?.location?.locationTheme?.code || 'ovatu')

          this.setReady(true)
        } else if (info) {
          await context.store.commit('auth/setAccountInfo', { info })
        }
      }
    },

    /**
     * Validate and sync the active account info & models
     */
    async syncActive ({ lock = false } = {}) {
      const active = this.active

      if (lock && active?.info?.location?.webPINRequired && !!active?.info?.employee?.pinHash) {
        context.store.commit('auth/setLock', { locked: true })

        return
      }

      if (active) {
        const info = await this.fetchInfo()

        const inactive = info?.plan?.isPlanSubscriptionStateInactive

        if (inactive && !info?.adminUser) {
          context.redirect('/account/inactive')

          this.helpscout()

          this.setReady(true)
        } else if (info) {
          await context.store.commit('auth/setAccountInfo', { info })

          context.$centrifuge.connect()

          await context.store.dispatch('customer/sync', { change: true })
          await context.store.dispatch('service/sync', { change: true })
          await context.store.dispatch('employee/sync', { change: true })
          await context.store.dispatch('segment/sync', { change: true })
          await context.store.dispatch('product/sync', { change: true })
          await context.store.dispatch('integration/sync', { change: true })
          await context.store.dispatch('form/sync', { change: true })
          await context.store.dispatch('voucher/sync', { change: true })
          await context.store.dispatch('customFields/sync', { change: true })
          await context.store.dispatch('discountModel/sync', { change: true })
          await context.store.dispatch('chargeModel/sync', { change: true })
          await context.store.dispatch('paymentModel/sync', { change: true })
          await context.store.dispatch('tax/sync', { change: true })

          this.helpscout()

          this.setTheme(info?.location?.locationTheme?.code || 'ovatu')

          this.setReady(true)
        }
      }
    },

    resetHydration () {
      context.store.commit('customer/setHydrated', false)
      context.store.commit('service/setHydrated', false)
      context.store.commit('employee/setHydrated', false)
      context.store.commit('segment/setHydrated', false)
      context.store.commit('product/setHydrated', false)
      context.store.commit('integration/setHydrated', false)
      context.store.commit('form/setHydrated', false)
      context.store.commit('voucher/setHydrated', false)
      context.store.commit('customFields/setHydrated', false)
      context.store.commit('discountModel/setHydrated', false)
      context.store.commit('chargeModel/setHydrated', false)
      context.store.commit('paymentModel/setHydrated', false)
      context.store.commit('tax/setHydrated', false)
    },

    helpscout () {
      if (context.$config.instance !== 'demo') {
        const employee = this.active?.info?.employee
        const location = this.active?.info?.location

        if (window && employee && location) {
          const data = {
            idClient: location.idClient,
            idLocation: location.idLocation,
            slug: location.slug,
            name: employee.displayName,
            email: employee.email,
            role: employee.role?.name,
            signature: employee.helpscoutSignature
          }

          try {
            window.Beacon?.('identify', data)
          } catch (e) {
            console.log(e)
          }
        }
      }
    },

    async clear () {
      try {
        const keys = await localforage.keys()
        const guids = this.accountIDs

        for (const key of keys) {
          const guid = key.split('.')[0]

          if (!guids.includes(guid)) {
            localforage.removeItem(key, () => console.log('removed: ' + key))
          }
        }
      } catch (error) {
        console.log(error)
      }
    },

    setMaintenance ({ title, content }) {
      context.store.commit('setMaintenance', { title, content })
      context.redirect('/maintenance')
    },

    setHome (home) {
      context.store.commit('auth/setHome', { home })
    },

    setSort ({ model, value }) {
      context.store.commit('auth/setSort', { model, value })
    },

    setLock (locked) {
      context.store.commit('auth/setLock', { locked })
    },

    setHistory (path) {
      context.store.commit('auth/setHistory', { path })
    },

    setAttempts (value) {
      context.store.commit('auth/setAttempts', { value })
    },

    setReady (value) {
      context.store.commit('auth/setReady', value)
    },

    setTheme (theme) {
      context.store.commit('setTheme', theme)
    },

    get clientId () {
      return 'bcbc0d85416f43a8ba6c004a3f509692'
    },

    get scopes () {
      return ['employee']
    },

    get headers () {
      return context.req ? context.req.headers : window.location
    },

    get host () {
      return context.req ? context.req.headers.host : window.location.host
    },

    get root () {
      const parts = this.host.split('.')
      return parts.length > 2 ? parts[2] : parts[1]
    },

    get domain () {
      const parts = this.host.split('.')
      return parts.length > 2 ? parts[1] : parts[0]
    },

    get location () {
      if (context.$config.forceLocation) {
        return context.$config.forceLocation
      }

      const parts = this.host.split('.')
      return parts.length > 2 ? parts[0] : null
    },

    get url () {
      return this.domain + '.' + this.root
    },

    get active () {
      return context.store.getters['auth/active']
    },

    get accounts () {
      return context.store.getters['auth/accounts']
    },

    get accountIDs () {
      return context.store.getters['auth/guids']
    },

    get info () {
      return context.store.getters['auth/info']
    },

    get locked () {
      return context.store.getters['auth/locked']
    },

    get ready () {
      return context.store.getters['auth/ready']
    },

    get home () {
      return context.store.getters['auth/home']
    },

    get sort () {
      return context.store.getters['auth/sort']
    },

    get theme () {
      const info = context.store.getters['auth/info']
      const theme = context.store.getters['theme']

      const code = theme || info?.location?.locationTheme?.code || 'ovatu'
      
      return Themes[code] || Themes['ovatu']
    }
  }

  inject('auth', plugin)

  if (!Vue.prototype.authPlugin) {
    Object.defineProperty(Vue.prototype, 'authPlugin', {
      get () {
        return plugin
      }
    })
  }

  return plugin.init()
}
