import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'
import { DateYMD, TimeMinute } from '../dates'

import { plugin as $date } from '../../plugins/date'

import Customer from './customer'
import CustomerPass from './customerPass'
import CustomField from './customField'
import CustomerForm from './customerForm'
import Employee from './employee'
import FrontDesk from './frontDesk'
import Integration, { IntegrationLabel, IntegrationAction, IntegrationActionType } from './integration'
import Order from './order'
import Recurrence from './recurrence'
import ReservationCustomer from './reservationCustomer'
import ReservationService from './reservationService'
import ReservationStatusType from './reservationStatusType'
import Service, { ServiceCustomerType } from './service'
import ServiceForm from './serviceForm'
import ServiceResource from './serviceResource'
import Pass from './pass'
import Voucher from './voucher'
import Upload from './upload'

export default class Reservation extends Model {
  static modelName () {
    return 'reservation'
  }

  static modelApiVersion () {
    return 2
  }

  objectID () {
    return this.idReservation
  }

  relations () {
    return {
      availableCustomerPasses: { type: CustomerPass },
      availableVouchers: { type: Voucher },
      customer: { type: Customer },
      customerPass: { type: CustomerPass },
      customerType: { type: ServiceCustomerType },
      customerRequestType: { type: ReservationCustomerRequestType },
      customFields: { type: CustomField },
      date: { type: DateYMD },
      employee: { type: Employee },
      finishTimeUTC: { type: Date },
      options: { type: ReservationOptions },
      order: { type: Order },
      recurrence: { type: Recurrence },
      reservationCustomer: { type: ReservationCustomer },
      scheduleType: { type: ReservationScheduleType },
      service: { type: Service },
      services: { type: ReservationService },
      serviceType: { type: ReservationServiceType },
      startTime: { type: TimeMinute },
      startTimeUTC: { type: Date },
      status: { type: ReservationStatus },
      statusType: { type: ReservationStatusType },
      attendees: { type: ReservationAttendees },
      groupCustomers: { type: ReservationCustomer }, // Did this change at some point or should it still be attendees?
      forms: { type: CustomerForm },
      relatedForms: { type: ReservationRelatedForm },
      integrations: { type: ReservationIntegration },
      voucher: { type: Voucher }
    }
  }

  additionalPropertiesForModelInstantiation () {
    return {
      date: this.date.integer
    }
  }

  saturate () {
    const that = this

    return this.constructor.get(this.objectID(), this.date).then((item) => {
      // update this object with response
      that.saturated = new Date()

      for (const key in item) {
        that[key] = item[key]
      }

      return that
    })
  }

  get display () {
    return this.infoLabel
  }

  get isRecurring () {
    return !!this.recurrenceGuid
  }

  get isGroup () {
    return this.customerType === ServiceCustomerType.group
  }

  get isSingle () {
    return this.customerType === ServiceCustomerType.single
  }

  get isMultiService () {
    return this.serviceType === ReservationServiceType.multi
  }

  get isStandardSchedule () {
    return this.scheduleType === ReservationScheduleType.standard
  }

  get isTimetableSchedule () {
    return this.scheduleType === ReservationScheduleType.timetable
  }

  get isStatusConfirmed () {
    return this.status === ReservationStatus.confirmed
  }

  get isStatusUnconfirmed () {
    return this.status === ReservationStatus.unconfirmed
  }

  get isStatusCancelled () {
    return this.status === ReservationStatus.cancelled
  }

  get hasVoucher () {
    return !!this.voucher && Object.keys(this.voucher).length
  }

  get hasPass () {
    return !!this.customerPass
  }

  get voucher () {
    const service =
      this.services.find(({ voucher }) => voucher &&
      Object.keys(voucher).length !== 0 &&
      voucher instanceof Voucher)

    return service ? service.voucher : null
  }

  get startLabel () {
    const startTimeUTC = $date.utc(this.startTimeUTC)
    const format = $date.presets.time

    return $date.format(startTimeUTC, format)
  }

  get finishLabel () {
    const finishTimeUTC = $date.utc(this.finishTimeUTC)
    const format = $date.presets.time

    return $date.format(finishTimeUTC, format)
  }

  get _servicesLabel () {
    return this.servicesLabel || this.services
      .map(({ service }) => service.title)
      .join(', ')
  }

  get employeesLabel () {
    if (this.services) {
      const employees = this.services
        .map(({ employee }) => {
          return employee.displayName
        })

      return Array.from(new Set(employees))
        .join(', ')
    }

    return this.employee?.displayName || ''
  }

  get durationLabel () {
    return this.services
      .map((service) => service.duration)
      .reduce((a, b) => a + b, 0)
  }


  get _startTime () {
    if (this.startTime) {
      return this.startTime
    }

    const firstService = this.services[0]
    return firstService.startTime || null
  }

  get _finishTime () {
    if (this.finishTime) {
      return this.finishTime
    }
    const lastService = this.services[this.services.length - 1]

    if (lastService) {
      return lastService.finishTime || lastService.startTime + lastService.duration
    }

    return null
  }

  static get (id, date) {
    const url = this.modelBaseURL() + '/' + id + '?date=' + date
    // return this.requestItem(Request.get(url), this)

    return this.requestItem(Request.get(url), this).then((item) => {
      item.saturated = new Date()

      return item
    })
  }

  static listIDs (ids) {
    // use a default runner (to call the list method)
    const that = this

    const runner = (page) => {
      return that.ids(ids, page)
    }

    return this.listRunner(runner)
  }

  save (future) {
    const wasNewRecord = this.isNewRecord

    let url
    let request

    if (wasNewRecord) {
      url = this.constructor.modelBaseURL() + '/' + this.objectID()
      request = Request.jsonPost(url, this.requestJSON())
    } else {
      const futureParam = (future) ? 1 : 0
      url = this.constructor.modelBaseURL() + '/' + this.objectID() + '?date=' + this.date + '&future=' + futureParam
      request = Request.jsonPut(url, this.requestJSON())
    }

    const that = this
    return this.constructor.requestItem(request, this.constructor).catch((err) => {
      throw err
    }).then((item) => {
      that.saturated = new Date()

      for (const key in item) {
        that[key] = item[key]
      }

      return that
    })
  }

  // Add cancellation reasons - reasons available in options key & cancellation reason note
  cancel ({ future, sendEmail, cancellationType, cancellationNote } = {}) {
    const sendEmailParam = sendEmail ? 1 : 0
    const futureParam = future ? 1 : 0
    const cancellationTypeParam = cancellationType ? cancellationType.idReservationCancellationType : null
    const cancellationNoteParam = cancellationNote
    const url = this.constructor.modelBaseURL() + '/cancel?id=' + this.objectID() + '&date=' + this.date + '&sendEmail=' + sendEmailParam + '&future=' + futureParam + '&type=' + cancellationTypeParam + '&note=' + cancellationNoteParam

    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  unCancel () {
    const url = this.constructor.modelBaseURL() + '/cancel?id=' + this.objectID() + '&date=' + this.date + '&undo=1'
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  delete (future) {
    const futureParam = future ? 1 : 0
    const url = this.constructor.modelBaseURL() + '/delete?id=' + this.objectID() + '&date=' + this.date + '&future=' + futureParam
    return this.constructor.requestSuccess(Request.get(url))
  }

  unDelete () {
    const url = this.constructor.modelBaseURL() + '/delete?id=' + this.objectID() + '&date=' + this.date + '&undo=1'
    return this.constructor.requestSuccess(Request.get(url))
  }

  restore () {
    const url = this.constructor.modelBaseURL() + '/restore?id=' + this.objectID() + '&date=' + this.date + '&undo=1'
    return this.constructor.requestSuccess(Request.get(url))
  }

  uploads ({ page = 1 } = {}) {
    const url = this.constructor.modelBaseURL() + '/uploads?id=' + this.objectID() + '&page=' + page
    return this.constructor.requestList(Request.get(url), Upload)
  }

  // list of available passes in options key
  // if availablepasses then select pass
  // find pass default to false

  checkIn (findPass, customerPasses) {
    const url = this.constructor.modelBaseURL() + '/checkIn?id=' + this.objectID()

    const data = {
      findPass,
      customerPasses
    }

    return this.constructor.requestItem(Request.post(url, JSON.stringify(data)), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  unCheckIn () {
    const url = this.constructor.modelBaseURL() + '/checkIn?id=' + this.objectID() + '&undo=1'
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  didNotShow () {
    const url = this.constructor.modelBaseURL() + '/noShow?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  unDidNotShow () {
    const url = this.constructor.modelBaseURL() + '/noShow?id=' + this.objectID() + '&undo=1'
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  confirm (sendMail) {
    const sendMailParam = sendMail ? 1 : 0
    const url = this.constructor.modelBaseURL() + '/confirm?id=' + this.objectID() + '&sendEmail=' + sendMailParam
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addService (service, employee, price, duration, processing, finishing, future) {
    const futureParam = (future) ? '1' : '0'
    const url = this.constructor.modelBaseURL() + '/addService?id=' + this.objectID() + '&service=' + service.idService + '&employee=' + employee.idEmployee + '&duration=' + duration + '&price=' + price + '&processing=' + processing + '&finishing=' + finishing + '&future=' + futureParam
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  updateService (reservationService, service, employee, price, date, startTime, duration, processing, finishing, future) {
    const futureParam = (future) ? '1' : '0'
    const params = {
      id: this.objectID(),
      reservationService: reservationService.idReservationService,
      service: service.idService,
      employee: employee.idEmployee,
      price,
      date,
      startTime,
      duration,
      processing,
      finishing,
      future: futureParam
    }

    const urlParams = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')

    const url = this.constructor.modelBaseURL() + '/updateService?' + urlParams
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  removeService (reservationService, future) {
    const futureParam = (future) ? '1' : '0'
    const url = this.constructor.modelBaseURL() + '/removeService?id=' + this.objectID() + '&reservationService=' + reservationService.idReservationService + '&future=' + futureParam
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  static recurOptions () {
    const url = this.modelBaseURL() + '/recurOptions'
    return this.requestItem(Request.get(url), ReservationRecurOptions)
  }

  previewCustomerRecurrence (customer, date) {
    const url = this.constructor.modelBaseURL() + '/previewCustomerRecurrence?id=' + this.objectID()

    const data = {
      customer: customer?.idCustomer,
      date
    }

    return this.constructor.requestItem(Request.post(url, JSON.stringify(data)), ReservationCustomerRecurrencePreview)
  }

  recurCustomers (reservationCustomers, reservations) {
    const url = this.constructor.modelBaseURL() + '/recurCustomers?id=' + this.objectID()

    const data = {
      reservationCustomers: reservationCustomers?.map(reservationCustomer => reservationCustomer.idReservationCustomer),
      reservations
    }

    return this.constructor.requestSuccess(Request.post(url, JSON.stringify(data)))
  }

  // TODO: unfinished in swift
  recur (recurRequest) {
    const url = this.constructor.modelBaseURL() + '/recur?id=' + this.objectID() + '&date=' + this.date
    // NOTE: unsure if this content type is needed
    const headers = {
      'Content-type': 'Content-type'
    }
    return this.constructor.requestItem(Request.post(url, this.toJSON(), headers), ReservationRecurResponse).then(this.updateSelf(res => this.updateSelf(res)))
  }

  combinedCommunications () {
    const url = this.constructor.modelBaseURL() + '/combinedCommunications?id=' + this.objectID() + '&date=' + this.date
    return this.constructor.requestItem(Request.get(url), FrontDesk)
  }

  frontDesk () {
    const url = this.constructor.modelBaseURL() + '/frontDesk?id=' + this.objectID() + '&date=' + this.date
    return this.constructor.requestItem(Request.get(url), FrontDesk)
  }

  email (type = ReservationEmailType.received) {
    const url = this.constructor.modelBaseURL() + '/email?id=' + this.objectID() + '&date=' + this.date + '&type=' + type.value
    return this.constructor.requestSuccess(Request.get(url))
  }

  assignCustomerPasses (passes) {
    const url = this.constructor.modelBaseURL() + '/assignCustomerPass?id=' + this.objectID()

    const data = {
      passes
    }

    return this.constructor.requestItem(Request.post(url, JSON.stringify(data)), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  applyVouchers (vouchers) {
    const url = this.constructor.modelBaseURL() + '/applyVoucher?id=' + this.objectID()

    const data = {
      vouchers
    }

    return this.constructor.requestItem(Request.post(url, JSON.stringify(data)), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  integrationAction (integration, action) {
    const url = this.constructor.modelBaseURL() + '/integrationAction?id=' + this.objectID() + '&date=' + this.date + '&guid=' + integration.guid + '&action=' + action.code
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  static unconfirmed () {
    const url = this.modelBaseURL() + '/unconfirmed'
    return this.requestList(Request.get(url), Reservation)
  }
}

export class ReservationOptions extends APIObject {
  relations () {
    return {
      statusTypes: { type: ReservationStatusType },
      customerRequestTypes: { type: ReservationOptionsCustomerRequestType },
      services: { type: ReservationServiceOptions }
    }
  }
}

export class ReservationServiceOptions extends APIObject {
  relations () {
    return {
      customerPasses: { type: CustomerPass },
      vouchers: { type: Voucher }
    }
  }
}

export class ReservationOptionsCustomerRequestType extends APIObject {
}

export class ReservationRecurOptions extends APIObject {
  relations () {
    return {
      types: { type: ReservationRecurType }
    }
  }
}

export class ReservationRecurType extends APIObject {
  relations () {
    return {
      options: { type: ReservationRecurTypeOption }
    }
  }
}

export class ReservationRecurTypeOption extends APIObject {
}

export class ReservationRecurRequest extends APIObject {
}

export class ReservationRecurResponse extends APIObject {
}

export class ReservationFrontDesk extends APIObject {
  relations () {
    return {
      customers: { type: ReservationCustomer },
      validPasses: { type: Pass }
    }
  }
}

export class ReservationAttendees extends APIObject {
  relations () {
    return {
      resources: { type: ReservationCustomerResource },
      customers: { type: ReservationCustomer },
      customerRequestType: { type: ReservationCustomerRequestType },
      status: { type: ReservationStatus },
      statusType: { type: ReservationStatusType }
    }
  }

  get isStatusConfirmed () {
    return this.status === ReservationStatus.confirmed
  }

  get isStatusUnconfirmed () {
    return this.status === ReservationStatus.unconfirmed
  }
}

export class ReservationRelatedForm extends APIObject {
  relations () {
    return {
      serviceForm: { type: ServiceForm },
      customerForm: { type: CustomerForm }
    }
  }
}

export class ReservationResource extends APIObject {
  relations () {
    return {
      serviceResource: { type: ServiceResource },
      resource: { type: Employee }
    }
  }
}

export class ReservationCustomerResource extends APIObject {
  relations () {
    return {
      serviceResource: { type: ServiceResource },
      resource: { type: Employee },
      customer: { type: Customer }
    }
  }
}

export class ReservationIntegration extends APIObject {
  relations () {
    return {
      integration: { type: Integration },
      labels: { type: IntegrationLabel },
      actions: { type: IntegrationAction }
    }
  }
}

export class ReservationCustomerRecurrencePreview extends APIObject {
  relations () {
    return {
      reservation: { type: Reservation },
      reservationCustomer: { type: ReservationCustomer }
    }
  }
}


export const ReservationServiceType = new Enum({
  single: { value: 0, description: 'Single' },
  multi: { value: 1, description: 'Multi' }
})

export const ReservationScheduleType = new Enum({
  standard: { value: 0 },
  timetable: { value: 1 }
})

export const ReservationStatus = new Enum({
  unconfirmed: { value: 0, description: 'Unconfirmed' },
  confirmed: { value: 1, description: 'Confirmed' },
  cancelled: { value: 2, description: 'Cancelled' }
})


export const ReservationCustomerRequestType = new Enum({
  none: { value: 0, description: 'None' },
  request: { value: 1, description: 'Existing: Employee requested' },
  newClient: { value: 2, description: 'New: Any employee' },
  newClientRequest: { value: 3, description: 'New: Employee requested' },
  transient: { value: 4, description: 'Existing: Any employee' }
})

export const ReservationEmployeeFunction = new Enum({
  none: { value: 0 },
  employee: { value: 1 },
  resource: { value: 2 }
})

export const ReservationEmailType = new Enum({
  received: { value: 'received', description: 'Received' },
  confirmation: { value: 'confirmation', description: 'Confirmation' },
  reminderEmail: { value: 'reminderEmail', description: 'Reminder (Email)' },
  reminderSMS: { value: 'reminderSMS', description: 'Reminder (SMS)' },
  reviewRequest: { value: 'reviewRequest', description: 'Review Request' }
})
