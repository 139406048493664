var render = function render(){var _vm=this,_c=_vm._self._c;return _c('container',_vm._b({staticClass:"relative w-full",attrs:{"base-class":[
    'w-full relative flex items-center h-9 text-gray-800 text-sm relative dark:text-gray-100',
    _vm.disabled ? 'opacity-75 pointer-events-none' : ''
  ]},scopedSlots:_vm._u([{key:"leading",fn:function(){return [_vm._t("leading")]},proxy:true},{key:"trailing",fn:function(){return [_vm._t("trailing")]},proxy:true}],null,true)},'container',_vm.container,false),[_c('o-dropdown',{ref:"dropdown",staticClass:"w-full",attrs:{"arrow":false,"placement":"bottom-start","container-class":"","detect-overflow":"","same-width":"","disabled":_vm.disabled},on:{"show":function($event){return _vm.$emit('show')}},scopedSlots:_vm._u([{key:"content",fn:function({ overflow }){return [_c('div',{staticClass:"text-sm flex flex-col w-120",style:({
          height: overflow && overflow.bottom > 0 ? `${400 - overflow.bottom}px` : '400px'
        })},[(_vm.search)?_c('div',{staticClass:"w-full flex items-center border-b"},[_c('o-icon',{staticClass:"text-gray-500 dark:text-gray-400 ml-4",attrs:{"icon":"search","size":14}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"py-2 px-3 w-full dark:bg-gray-900",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}})],1):_vm._e(),_vm._v(" "),_vm._t("content",function(){return [(_vm.filtered)?_c('o-scroll',_vm._l((_vm.filtered),function(category,indexCategory){return _c('ul',{key:indexCategory},[(category.label)?_c('li',{staticClass:"text-gray-500 dark:text-gray-400 text-xs uppercase tracking-wider mt-3 mb-1 px-4"},[_vm._v("\n                "+_vm._s(category.label)+"\n              ")]):_vm._e(),_vm._v(" "),_vm._l((category.options),function(option,indexOption){return _c('li',{key:indexOption,staticClass:"text-gray-800 cursor-pointer select-none relative py-2 px-3 transition-colors dark:text-gray-100",class:[
                  (_vm.isMulti ? _vm._value.includes(option.value) : option.value === _vm.value) ? 'bg-blue-50 text-blue-700 hover:bg-blue-100 dark:bg-blue-800/20 dark:text-blue-300 dark:hover:bg-blue-900/50' : 'hover:bg-gray-100 dark:hover:bg-gray-800',
                  option.class
                ],attrs:{"role":"option"},on:{"click":function($event){return _vm.handleOptionClick(option.value)}}},[_vm._t("option",function(){return [_c('span',{staticClass:"w-full flex items-center justify-between"},[_vm._v("\n                    "+_vm._s(option.label)+"\n\n                    "),(_vm.isMulti ? _vm._value.includes(option.value) : option.value === _vm.value)?_c('o-icon',{staticClass:"text-blue-600 dark:text-blue-400",attrs:{"icon":"check","size":9,"fill":""}}):_vm._e()],1)]},{"option":option,"query":_vm.query})],2)})],2)}),0):_c('p',{staticClass:"text-center p-4 text-gray-600 dark:text-gray-300"},[_vm._v("\n            No options found.\n          ")])]}),_vm._v(" "),(_vm.clear && !_vm.isEmpty)?_c('div',{staticClass:"w-full border-t pt-1 px-1"},[_c('ul',[_c('li',{staticClass:"text-gray-800 cursor-pointer select-none relative py-1 transition-colors hover:bg-gray-100 w-full block text-center rounded",attrs:{"role":"option"},on:{"click":_vm.handleClear}},[_c('span',{staticClass:"w-full flex items-center justify-center"},[_vm._v("\n                Clear selected\n\n                "),_c('o-icon',{staticClass:"text-blue-600 ml-2",attrs:{"icon":"x","size":12,"fill":""}})],1)])])]):_vm._e()],2)]}}],null,true)},[_c('div',{staticClass:"w-full h-9 relative flex items-center justify-between text-sm text-gray-800 cursor-pointer pr-4 px-2 dark:text-gray-100"},[(!_vm.isEmpty)?_vm._t("value",function(){return [_c('span',{staticClass:"truncate flex-shrink max-w-[120px]"},[_vm._v("\n          "+_vm._s(_vm.valueLabel)+"\n        ")])]},{"value":_vm.value}):_vm._e(),_vm._v(" "),(_vm.isEmpty)?_c('p',{staticClass:"text-sm text-gray-500 dark:text-gray-400"},[_vm._v("\n        "+_vm._s(_vm.placeholder)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"absolute inset-y-0 right-0 flex items-center px-2 text-gray-600"},[_c('o-icon',{attrs:{"icon":"accordion","size":12}})],1)],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }