import { Centrifuge, subscribingCodes } from 'centrifuge';
import Vue from 'vue'

class Event {
  constructor (name, { model, toast }) {
    this.name = name
    this.model = model
    this.toast = toast
  }

  emit (context, data) {
    if (data && this.model) {
      context.$bus.emit.created(this.model, data)
    }
  }
}

const eventTypes = {
  'message-received': new Event('message-received', {
    model: 'CustomerMessage',
    toast: (context, data) => {
      if (data && data.sender === 2) {
        Vue.notification.toast({
          title: `Message from ${data.senderLabel}`,
          text: data.title || data.body,
          action: {
            label: 'View',
            click: () => {
              if (!context.route.path.includes(`message/${data.idCustomer}`)) {
                context.$panel.create(`/message/chat/${data.idCustomer}`)
              }
            }
          }
        })
      }
    }
  })
}

export default (context, inject) => {
  const plugin = {
    instance: null,
    channel: null,
    async connect () {
      try {
        const $auth = context.$auth;
        const events = $auth?.info?.events;

        if (events && events.enabled) {
          const that = this

          this.disconnect();

          this.instance = new Centrifuge(events.endpoints.websocket, {
            token: events.token
          });

          this.instance.on('connected', function(ctx) {
            // now client connected to Centrifugo and authenticated.
            console.log(ctx)
          });

          this.instance.on('disconnected', function(ctx) {
            // now client disconnected from Centrifugo
            console.log(ctx)
          })

          this.instance.on('subscribing', function(ctx) {
            // now client connected to Centrifugo and authenticated.
            // console.log('subscribing')
          });

          this.instance.on('subscribed', function(ctx) {
            // now client connected to Centrifugo and authenticated.
            // console.log(ctx)
          });

          this.instance.on('unsubscribed', function(ctx) {
            // now client disconnected from Centrifugo
            // console.log(ctx)
          })

          this.instance.on('publication', function(ctx) {
            const eventType = eventTypes[ctx?.data?.event]

            if (eventType) {
              const data = ctx?.data

              eventType.emit(context, data)

              if (eventType.toast) {
                eventType.toast(context, data)
              }
            }
          })

          this.instance.on('error', function(ctx) {
            // now client disconnected from Centrifugo
            console.log(ctx)
          })


          await this.instance.connect();
        }
      } catch (e) {
        console.log(e)
      }
    },
    disconnect () {
      if (this.instance) {
        this.instance.disconnect();
        this.channel = null;
      }
    }
  }

  inject('centrifuge', plugin)
}
