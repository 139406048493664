<template>
  <component
    :is="tag"
    :contenteditable="contenteditable"
    @input="update"
    @blur="update"
    @paste="onPaste"
    @keypress="onKeypress"
    ref="element"
  >
  </component>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: 'div'
    },
    contenteditable: {
      type: [Boolean, String],
      default: true
    },
    noHtml: {
      type: Boolean,
      default: true
    },
    noNl: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.updateContent(this.value ?? '')
  },
  watch: {
    value: {
      handler: function(newval, oldval) {
        if(newval != this.currentContent()){
          this.updateContent(newval ?? '')
        }
      },
      immediate: true
    },
    noHtml: {
      handler: function(newval, oldval) {
        this.updateContent(this.value ?? '')
      },
      immediate: true
    },
    tag: {
      handler: function(newval, oldval) {
        this.updateContent(this.value ?? '')
      },
      immediate: true
    }
  },
  methods: {
    currentContent(){
      return this.noHtml
        ? this.$refs.element?.innerText
        : this.$refs.element?.innerHTML
    },
    replaceAll(str, search, replacement) {
      return str
        .split(search)
        .join(replacement)
    },
    updateContent(newcontent){
      if (this.$refs.element) {
        if (this.noHtml) {
          this.$refs.element.innerText = newcontent;
        } else {
          this.$refs.element.innerHTML = newcontent;
        }
      }
    },
    update(event) {
      this.$emit('input', this.currentContent());
    },
    onPaste(event) {
      event.preventDefault();
      let text = (event.originalEvent || event).clipboardData.getData('text/plain');
      if(this.noNl) {
        text = replaceAll(text, '\r\n', ' ');
        text = replaceAll(text, '\n', ' ');
        text = replaceAll(text, '\r', ' ');
      }

      window.document.execCommand('insertText', false, text);
    },
    onKeypress(event) {
      if(event.key == 'Enter' && this.noNl) {
        event.preventDefault();
        this.$emit('returned', currentContent());
      }
    }
  }
}
</script>
